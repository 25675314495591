import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, Button, Box, Fab, Card, Avatar, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Domain from "@mui/icons-material/Domain";
import EmailIcon from "@mui/icons-material/Email";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ArticleIcon from "@mui/icons-material/Article";
import MaijaContainer from "../../components/MaijaContainer";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaErrorPage from "../../components/MaijaErrorPage";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getJobCoachDetails, sendEmail, removeJobCoach, updateProfilePicture } from "./JobCoachDetailsRepository";
import { pushJobCoach } from "../../reducers/jobCoachDetailsPageSlice";
import MaijaInfoCard from "../../components/MaijaInfoCard";
import { ApplicantList } from "../applicant-list/ApplicantsListPage";
import MaijaSendEmailModal from "../../components/MaijaSendEmailModal";
import { useTranslation } from "react-i18next";
import { setCompanyCreateSelectedJobCoach, setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { jobCoachDetailsDetailsToJobCoachItem } from "../../types/JobCoachDetails";
import { COMPANY_CREATE_ROUTE, CREATE_APPLICANT_ROUTE, JOB_COACH_LIST_ROUTE } from "../../Routes";
import { ConfirmActionDialog } from "../../components/ConfirmActionDialog";
import { SecurityLevel } from "../../types/User";
import { CameraAlt, Phone as PhoneIcon } from "@mui/icons-material";
import { setProfilePictureUrl } from "../../reducers/userSlice";
import { People } from "@mui/icons-material";
import JobCoachDetailsField, { JobCoachDetailsFieldType } from "./JobCoachDetailsField";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import axiosInstance from "../../utils/AxiosInstance";
import { AuthContext } from "../../providers/AuthProvider";
import JobCoachReportPreviewModal from "./JobCoachReportPreviewModal";
import { PreviewModalMode } from "../../components/ReportPreviewModal";
import { JobCoachReportsComponent } from "./JobCoachReportsComponent";

interface Office {
  id: string;
  name: string;
}

const JobCoachDetailsPage = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const jobCoach = useAppSelector((state) => (id ? state.jobCoachDetailsPageSlice.jobCoachesMap[id] : undefined));

  const { user } = useContext(AuthContext);
  const isCompanyAdmin = user?.securityLevel === SecurityLevel.CompanyAdmin;
  const profilePictureUrl = jobCoach?.profilePictureUrl;

  const [isLoading, setIsLoading] = useState(true);
  const displayedColumns = useAppSelector((state) => state.applicantListPageSlice.displayedColumns);

  const [reportModalOpen, setReportModalOpen] = React.useState(false);
  const [reportModalMode, setReportModalMode] = useState<PreviewModalMode>(PreviewModalMode.GENERATE);
  const [sendEmailModalOpen, setSendEmailModalOpen] = React.useState(false);
  const [removeJobCoachModalOpen, setRemoveJobCoachModalOpen] = React.useState(false);

  const handleRemoveJobCoach = async (jobCoachId: string) => {
    try {
      const success = await removeJobCoach(jobCoachId);
      if (success) {
        navigate(JOB_COACH_LIST_ROUTE.path);
        dispatch(
          showSnackbar({
            message: t("jobCoachDetails.jobCoachRemoved"),
          }),
        );
      } else {
        dispatch(
          showSnackbar({
            message: t("jobCoachDetails.failedToRemoveJobCoach"),
            severity: "error",
          }),
        );
      }
    } catch (error) {
      console.error("Error removing job coach:", error);
      dispatch(
        showSnackbar({
          message: t("jobCoachDetails.failedToRemoveJobCoach"),
          severity: "error",
        }),
      );
    }
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        try {
          if (!id) {
            return;
          }
          const profileIconUrl = await updateProfilePicture(base64String, id);
          const jobCoach = await getJobCoachDetails(id);
          if (!jobCoach) {
            throw new Error("Job coach details not found");
          }
          jobCoach.profilePictureUrl = profileIconUrl;
          dispatch(setProfilePictureUrl(profileIconUrl));
          dispatch(pushJobCoach(jobCoach));
        } catch (error) {
          dispatch(showSnackbar({ message: "Failed to update profile picture", severity: "error" }));
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenReportModal = (reportId: string | null) => {
    if (reportId) {
      setReportModalMode(PreviewModalMode.LOAD_EXISTING(reportId));
    } else {
      setReportModalMode(PreviewModalMode.GENERATE);
    }
    setReportModalOpen(true);
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    getJobCoachDetails(id)
      .then((data) => {
        if (data) {
          dispatch(pushJobCoach(data));
        }
      })
      .finally(() => setIsLoading(false));
  }, [id, dispatch]);

  const [offices, setOffices] = useState<Office[]>([]);

  useEffect(() => {
    if (isCompanyAdmin) {
      axiosInstance
        .get("/offices_list")
        .then((response) => setOffices(response.data))
        .catch((error) => console.error("Error fetching offices:", error));
    }
  }, [isCompanyAdmin]);

  if (isLoading) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  if (!jobCoach) {
    return (
      <MaijaContainer style={{ height: "100%" }}>
        <MaijaErrorPage isFullscreen={true} />
      </MaijaContainer>
    );
  }

  return (
    <MaijaContainer>
      {isCompanyAdmin && (
        <Fab
          variant="extended"
          color="secondary"
          style={{
            position: "fixed",
            bottom: "60px",
            right: "60px",
          }}
          onClick={() => setReportModalOpen(true)}
        >
          <AddIcon sx={{ mr: 1 }} />
          <Typography variant="body2">{t("jobCoachDetails.generateReport")}</Typography>
        </Fab>
      )}

      <JobCoachReportPreviewModal
        jobCoachId={jobCoach.jobCoachId}
        jobCoachName={jobCoach.name}
        isOpen={reportModalOpen}
        reportMode={reportModalMode}
        handleClose={() => {
          setReportModalOpen(false);
          setReportModalMode(PreviewModalMode.GENERATE);
        }}
      />

      <ConfirmActionDialog
        open={removeJobCoachModalOpen}
        title={t("jobCoachDetails.removeJobCoachConfirmationTitle", { name: jobCoach.name })}
        description={t("jobCoachDetails.removeJobCoachConfirmationDescription", { name: jobCoach.name })}
        onClose={() => setRemoveJobCoachModalOpen(false)}
        onAction={() => handleRemoveJobCoach(jobCoach.jobCoachId)}
      />

      <MaijaSendEmailModal
        sendToName={jobCoach.name}
        isOpen={sendEmailModalOpen}
        onSendEmail={(subject, body) => sendEmail(jobCoach.jobCoachId, subject, body)}
        onSuccess={() =>
          dispatch(
            showSnackbar({
              message: t("jobCoachDetails.emailSent"),
            }),
          )
        }
        handleClose={() => setSendEmailModalOpen(false)}
      />

      <Box display="flex" flexDirection="column">
        <Card sx={{ m: 2, p: 5 }} elevation={1}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Box display="flex" flexDirection="row" alignItems="center" sx={{ mx: 1 }}>
              <Box sx={{ display: "inline-flex", justifyContent: "center", position: "relative" }}>
                <Avatar
                  sx={{ width: 170, height: 170, mr: 4 }}
                  src={profilePictureUrl || undefined}
                  alt={jobCoach.name}
                >
                  {!isCompanyAdmin && !profilePictureUrl && (
                    <IconButton sx={{ width: 170, height: 170 }} component="label">
                      <CameraAlt />
                      <input type="file" hidden onChange={handleFileChange} />
                    </IconButton>
                  )}
                </Avatar>
                {!isCompanyAdmin && profilePictureUrl && (
                  <IconButton
                    component="label"
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      width: 40,
                      height: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                      },
                    }}
                  >
                    <CameraAlt />
                    <input type="file" hidden onChange={handleFileChange} />
                  </IconButton>
                )}
              </Box>

              <Box display="flex" flexDirection="column" sx={{ ml: 2 }}>
                <Typography variant="h4">{jobCoach.name}</Typography>
                {isCompanyAdmin ? (
                  <Box
                    display="inline-flex"
                    alignItems="center"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <JobCoachDetailsField
                      type={JobCoachDetailsFieldType.OfficeId}
                      info={jobCoach?.officeName}
                      getUpdatedJobCoachDetails={(value: string) => {
                        if (jobCoach) {
                          const selectedOffice = offices.find((office) => office.id === value);
                          const updatedJobCoach = {
                            ...jobCoach,
                            officeId: value,
                            officeName: selectedOffice ? selectedOffice.name : jobCoach.officeName,
                          };
                          dispatch(pushJobCoach(updatedJobCoach));
                          return updatedJobCoach;
                        }
                        return undefined;
                      }}
                      jobCoachId={jobCoach.jobCoachId}
                      leadingSlot={<Domain sx={{ mr: 2, fontSize: 25 }} />}
                      offices={offices}
                    />
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Domain sx={{ mr: 2, fontSize: 25 }} />
                    <Typography variant="subtitle1">{jobCoach.officeName}</Typography>
                  </Box>
                )}
                {isCompanyAdmin ? (
                  <JobCoachDetailsField
                    type={JobCoachDetailsFieldType.PhoneNumber}
                    info={jobCoach?.phoneNumber}
                    getUpdatedJobCoachDetails={(value: string) => {
                      if (jobCoach) {
                        const updatedJobCoach = {
                          ...jobCoach,
                          phoneNumber: value,
                        };
                        dispatch(pushJobCoach(updatedJobCoach));
                        return updatedJobCoach;
                      }
                      return undefined;
                    }}
                    jobCoachId={jobCoach.jobCoachId}
                    leadingSlot={<LocalPhoneIcon sx={{ mr: 2, fontSize: 25 }} />}
                    offices={offices}
                  />
                ) : (
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <PhoneIcon sx={{ mr: 2 }} />
                    <Typography variant="subtitle1">{jobCoach.phoneNumber}</Typography>
                  </Box>
                )}
                <Box display="flex" flexDirection="row" alignItems="center">
                  <EmailIcon sx={{ mr: 2 }} />
                  <Typography variant="subtitle1">{jobCoach.email}</Typography>
                </Box>
              </Box>
            </Box>
            {isCompanyAdmin && (
              <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ mx: 2 }}>
                <Button
                  variant="contained"
                  sx={{ py: 2, px: 4, my: 2, borderRadius: "50px" }}
                  onClick={() => {
                    setSendEmailModalOpen(true);
                  }}
                >
                  {t("jobCoachDetails.sendEmail")}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ py: 2, px: 4, my: 2, borderRadius: "50px" }}
                  onClick={() => {
                    setRemoveJobCoachModalOpen(true);
                  }}
                >
                  {t("jobCoachDetails.remove")}
                </Button>
              </Box>
            )}
          </Box>
        </Card>

        <Box mt={3} display="flex" flexDirection="row" justifyContent="space-around">
          <MaijaInfoCard
            icon={<People />}
            title={t("jobCoachDetails.applicants")}
            value={jobCoach.numberApplicants}
            navigateToAdd={() => {
              if (isCompanyAdmin) {
                dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.ApplicantTab));
                if (jobCoach) {
                  dispatch(setCompanyCreateSelectedJobCoach(jobCoachDetailsDetailsToJobCoachItem(jobCoach)));
                } else {
                  dispatch(setCompanyCreateSelectedJobCoach(null));
                }
                navigate(COMPANY_CREATE_ROUTE.path);
              } else {
                navigate(CREATE_APPLICANT_ROUTE.path);
              }
            }}
          />
          <MaijaInfoCard
            icon={<ArticleIcon />}
            title={t("jobCoachDetails.appliedJobs")}
            value={jobCoach.numberAppliedJob}
          />
          <MaijaInfoCard icon={<HowToRegIcon />} title={t("jobCoachDetails.foundJobs")} value={jobCoach.numberGotJob} />
        </Box>
      </Box>

      {isCompanyAdmin && (
        <Box sx={{ mt: 2, mx: 2 }} display="flex" flexDirection="column">
          <Typography variant="h4" sx={{ mb: 3 }}>
            {t("jobCoachDetails.applicantsTitle")}
          </Typography>
          <ApplicantList applicants={jobCoach.applicantItems} displayedColumns={displayedColumns} />
        </Box>
      )}

      <Box sx={{ mt: 5, mx: 2, mb: 30 }} display="flex" flexDirection="column">
        <Typography variant="h4" sx={{ mb: 0 }}>
          {t("jobCoachDetails.reportsTitle")}
        </Typography>
        <JobCoachReportsComponent
          jobCoachId={jobCoach.jobCoachId}
          onShowReport={(reportId) => {
            handleOpenReportModal(reportId);
          }}
        />
      </Box>
    </MaijaContainer>
  );
};

export default JobCoachDetailsPage;
