import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import { Box, Button, Grid, Paper, FormControl, InputLabel, Select, MenuItem, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Office {
  id: string;
  name: string;
}

interface ChangeOfficeComponentProps {
  applicantId: string;
  onOfficeChange: (newOfficeName: string) => void;
}

const ChangeOfficeComponent: React.FC<ChangeOfficeComponentProps> = ({ applicantId, onOfficeChange }) => {
  const [offices, setOffices] = useState<Office[]>([]);
  const [selectedOfficeId, setSelectedOfficeId] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const { t } = useTranslation();

  useEffect(() => {
    axiosInstance
      .get(`/offices_list`)
      .then((response) => setOffices(response.data))
      .catch(() => setErrorMessage(t("applicantDetails.errorFetchingOffices")));
  }, [t]);

  const handleChangeOffice = () => {
    if (!selectedOfficeId) {
      setErrorMessage(t("applicantDetails.selectOffice"));
      return;
    }

    axiosInstance
      .put(`/applicants/${applicantId}/office`, { office_id: selectedOfficeId })
      .then(() => {
        const updatedOffice = offices.find((office) => office.id === selectedOfficeId);
        if (updatedOffice) {
          onOfficeChange(updatedOffice.name);
        }
        setSuccessMessage(t("applicantDetails.officeUpdated"));
        setErrorMessage("");
      })
      .catch(() => {
        setErrorMessage(t("applicantDetails.failedToUpdateOffice"));
        setSuccessMessage("");
      });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Paper elevation={3} sx={{ p: 2, mb: 4, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="office-select-label">{t("applicantDetails.office")}</InputLabel>
              <Select
                labelId="office-select-label"
                value={selectedOfficeId}
                onChange={(e) => setSelectedOfficeId(e.target.value as string)}
                label={t("applicantDetails.office")}
              >
                {offices.map((office) => (
                  <MenuItem key={office.id} value={office.id}>
                    {office.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" onClick={handleChangeOffice}>
              {t("generic.submit")}
            </Button>
          </Grid>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          {successMessage && (
            <Grid item xs={12}>
              <Alert severity="success">{successMessage}</Alert>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ChangeOfficeComponent;
