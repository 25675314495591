import { ApplicantDetails } from "../../types/ApplicantDetails";
import { Application } from "../../types/Application";
import { PlatsbankenJobsCounts } from "../../types/PlatsbankenJobsCounts";
import axiosInstance from "../../utils/AxiosInstance";

export async function getApplicantDetails(applicantId: string): Promise<ApplicantDetails | undefined> {
  try {
    const response = await axiosInstance.get("/applicants_details", {
      params: {
        applicant_id: applicantId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function updateApplicantStatus(id: string, newStatus: string): Promise<void> {
  try {
    await axiosInstance.put("/applicant/status", {
      applicant_id: id,
      status: newStatus,
    });
  } catch (error) {
    console.error("Error updating applicant status:", error);
    throw error;
  }
}

export async function sendEmail(applicantId: string, subject: string, body: string): Promise<boolean> {
  try {
    await axiosInstance.post("/applicant/email", {
      applicant_id: applicantId,
      email_subject: subject,
      email_body: body,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function removeApplicant(applicantId: string): Promise<null> {
  try {
    await axiosInstance.post("/applicant/delete", {
      applicant_id: applicantId,
    });
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function applicantGotJob(applicantId: string, gotJob: boolean): Promise<null> {
  try {
    await axiosInstance.post("/applicant/got_job", {
      applicant_id: applicantId,
      got_job: gotJob,
    });
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export type DownloadApplicationResponse = {
  resume: string;
  coverLetter: string;
  application: Application;
};

export async function downloadApplication(applicationId: string): Promise<DownloadApplicationResponse> {
  try {
    const response = await axiosInstance.get("/applicant/download_application", {
      params: {
        application_id: applicationId,
      },
    });
    return {
      resume: response.data.resume,
      coverLetter: response.data.cover_letter,
      application: response.data.application,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function verifyEducation(applicantId: string, educationId: string, verified: boolean): Promise<boolean> {
  try {
    await axiosInstance.post("/verify/education", {
      applicant_id: applicantId,
      education_id: educationId,
      verified: verified,
    });
    return true;
  } catch (error) {
    console.error("Error verifying education:", error);
    return false;
  }
}

export async function verifySfi(applicantId: string, verified: boolean): Promise<boolean> {
  try {
    await axiosInstance.post("/verify/sfi", {
      applicant_id: applicantId,
      verified: verified,
    });
    return true;
  } catch (error) {
    console.error("Error verifying SFI level:", error);
    return false;
  }
}

export async function fetchPlatsbankenJobCounts(applicantId: string): Promise<PlatsbankenJobsCounts> {
  try {
    const response = await axiosInstance.get<PlatsbankenJobsCounts>(`/platsbanken_job_counts`, {
      params: { applicant_id: applicantId },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch PlatsbankenJobsCounts:", error);
    throw error;
  }
}
