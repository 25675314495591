import { useState } from "react";
import { Backdrop, CircularProgress, Box } from "@mui/material";
import successAnimationData from "../../assets/lottie/success.json";
import MaijaContainer from "../../components/MaijaContainer";
import MaijaLottieAnimation from "../../components/MaijaLottieAnimation";
import CreateApplicantForm from "./CreateApplicantForm";

const CreateApplicantPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [successAnimation, setSuccessAnimation] = useState<boolean>(false);

  return (
    <MaijaContainer>
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>
      <MaijaLottieAnimation animationData={successAnimationData} size={300} show={successAnimation} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding={5}
        style={{ minHeight: "100%", opacity: loading ? 0.5 : 1 }}
      >
        <CreateApplicantForm isLoading={loading} setLoading={setLoading} setSuccessAnimation={setSuccessAnimation} />
      </Box>
    </MaijaContainer>
  );
};

export default CreateApplicantPage;
