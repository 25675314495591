import React from "react";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

interface ClickableHeaderItemProps {
  text: string;
  onClick: () => void;
  sortOrder?: "asc" | "desc";
}

const ClickableHeaderItem: React.FC<ClickableHeaderItemProps> = ({ text, onClick, sortOrder }) => {
  return (
    <ButtonBase
      sx={{
        py: 2,
        borderRadius: 1,
        display: "flex",
        justifyContent: "flex-start",
      }}
      style={{ width: "100%" }}
      onClick={onClick}
    >
      <Typography style={{ userSelect: "none", alignSelf: "flex-start" }}>{text}</Typography>
      {sortOrder === "asc" && <ArrowUpward fontSize="small" />}
      {sortOrder === "desc" && <ArrowDownward fontSize="small" />}
    </ButtonBase>
  );
};

export default ClickableHeaderItem;
