import React from "react";
import { Box, ListItem, Typography } from "@mui/material";
import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { TFunction } from "i18next";
import { formatDateToString } from "../../utils/DateUtils";

interface AnalyticsTableItemProps {
  applicant: ApplicantAnalyticsItem;
  t: TFunction;
}

const AnalyticsTableItemComponent: React.FC<AnalyticsTableItemProps> = ({ applicant, t }) => {
  return (
    <ListItem key={applicant.id} sx={{ padding: 4, borderBottom: "1px solid #ddd" }}>
      <Box display="flex" minWidth="1500px">
        <Box width={250} p={1}>
          <Typography variant="body2">{t(`genderEnum.${applicant.gender}`)}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{applicant.age}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{applicant.evaluationCriteria}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{applicant.appliedJobsLast30Days}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{applicant.nonAppliedJobsCount}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{formatDateToString(applicant.latestLogin)}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{`${Number(applicant.profileCompletion) * 100}%`}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{t(`educationLevelsEnum.${applicant.educationLevel}`)}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">{t(`sfiLevelsEnum.${applicant.sfiLevel}`)}</Typography>
        </Box>
        <Box width={250} p={1}>
          <Typography variant="body2">
            {applicant.canTakeWorkImmediately ? t("generic.yes") : t("generic.no")}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

export default React.memo(AnalyticsTableItemComponent);
