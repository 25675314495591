import React, { useEffect, useCallback } from "react";
import axiosInstance from "../../utils/AxiosInstance";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Backdrop,
  Divider,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import MaijaLottieAnimation from "../../components/MaijaLottieAnimation";
import successAnimation from "../../assets/lottie/success.json";
import { useAppDispatch, useAppSelector } from "../../hooks";
import LastDayEdit from "./LastDayEdit";
import { useTranslation } from "react-i18next";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { verifySfi } from "./ApplicantsDetailRepository";
import { addApplicantSupportInfoItem, setApplicantSupportInfoList } from "../../reducers/applicantDetailsPageSlice";

interface ApplicantSupportProps {
  applicantId: string;
}

const ApplicantSupportInfoComponent: React.FC<ApplicantSupportProps> = ({ applicantId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const supportInfo = useAppSelector((state) => state.applicantDetailsPageSlice.supportInfoItemsMap[applicantId]);
  const [loading, setLoading] = React.useState(!supportInfo);
  const [success, setSuccess] = React.useState<boolean>(false);

  useEffect(() => {
    const fetchSupportInfo = async () => {
      try {
        const response = await axiosInstance.get(`/applicant_support_info/${applicantId}`);
        dispatch(setApplicantSupportInfoList({ applicantId, supportInfo: response.data }));
      } catch (error) {
        console.error("Error fetching applicant support info:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!supportInfo) {
      fetchSupportInfo();
    }
  }, [applicantId, dispatch, supportInfo]);

  const handleSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 2000);
  };

  const handleSfiLevelVerifiedChange = useCallback(
    async (isVerified: boolean) => {
      if (!supportInfo) return;

      try {
        const success = await verifySfi(applicantId, isVerified);
        if (!success) {
          throw new Error("SFI level verification failed");
        }

        dispatch(
          addApplicantSupportInfoItem({
            applicantId,
            supportInfo: { ...supportInfo, sfiLevelVerified: isVerified },
          }),
        );

        if (isVerified) {
          dispatch(
            showSnackbar({
              message: t("applicantDetails.sfiLevelVerified"),
            }),
          );
        }
      } catch (error) {
        dispatch(
          showSnackbar({
            message: t("generic.error"),
            severity: "error",
          }),
        );
        console.error("Error verifying SFI level:", error);
      }
    },
    [applicantId, supportInfo, dispatch, t],
  );

  if (loading && !success) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!supportInfo) {
    return <Typography>{t("applicantDetails.noSupportInformationFound.")}</Typography>;
  }

  const renderSupportInfoItem = (
    label: string,
    value: string,
    index: number,
    additionalComponent?: React.ReactNode,
  ) => (
    <ListItem key={label} sx={{ backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff" }}>
      <ListItemText primary={label} secondary={value} />
      {additionalComponent && <Box>{additionalComponent}</Box>}
    </ListItem>
  );

  return (
    <Box sx={{ mt: 4, p: 2 }}>
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>
      <MaijaLottieAnimation animationData={successAnimation} size={300} show={success} />

      <LastDayEdit applicantId={applicantId} initialLastDay={supportInfo.lastDay} onSuccess={handleSuccess} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t("applicantDetails.workAvailability")}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List sx={{ maxHeight: 300, overflow: "auto" }}>
                {renderSupportInfoItem(
                  t("applicantDetails.hasWorkLimitations"),
                  supportInfo.hasWorkLimitations ? t("applicantDetails.yes") : t("applicantDetails.no"),
                  0,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.canTakeWorkImmediately"),
                  supportInfo.canTakeWorkImmediately ? t("applicantDetails.yes") : t("applicantDetails.no"),
                  1,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.canWorkDaytime"),
                  supportInfo.canWorkDaytime ? t("applicantDetails.yes") : t("applicantDetails.no"),
                  2,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.canWorkEvening"),
                  supportInfo.canWorkEvening ? t("applicantDetails.yes") : t("applicantDetails.no"),
                  3,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.canWorkNighttime"),
                  supportInfo.canWorkNighttime ? t("applicantDetails.yes") : t("applicantDetails.no"),
                  4,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.fullTime"),
                  supportInfo.fullTime ? t("applicantDetails.yes") : t("applicantDetails.no"),
                  5,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.partTimePercentage"),
                  supportInfo.partTimePercentage !== null
                    ? `${supportInfo.partTimePercentage}%`
                    : t("applicantDetails.n/a"),
                  6,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.unemploymentOrSickLeaveDate"),
                  supportInfo.unemploymentOrSickLeaveDate || t("applicantDetails.n/a"),
                  7,
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {t("applicantDetails.education&Preferences")}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List sx={{ maxHeight: 300, overflow: "auto" }}>
                {renderSupportInfoItem(
                  t("applicantDetails.sfiLevel"),
                  t(`sfiLevelsEnum.${supportInfo.sfiLevel}`),
                  0,
                  <FormControlLabel
                    control={
                      <Switch
                        checked={supportInfo.sfiLevelVerified}
                        onChange={(event) => handleSfiLevelVerifiedChange(event.target.checked)}
                        color="primary"
                      />
                    }
                    label={t("applicantDetails.verify")}
                  />,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.educationLevel"),
                  t(`educationLevelsEnum.${supportInfo.educationLevel}`),
                  1,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.firstPreferredJob"),
                  supportInfo.firstPreferredJob || t("applicantDetails.n/a"),
                  2,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.secondPreferredJob"),
                  supportInfo.secondPreferredJob || t("applicantDetails.n/a"),
                  3,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.thirdPreferredJob"),
                  supportInfo.thirdPreferredJob || t("applicantDetails.n/a"),
                  4,
                )}
                {renderSupportInfoItem(
                  t("applicantDetails.desiredJobinFiveYears"),
                  supportInfo.desiredJobInFiveYears || t("applicantDetails.n/a"),
                  5,
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ApplicantSupportInfoComponent;
