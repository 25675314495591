import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { JobCoachItem } from "../../types/JobCoachItem";
import { OfficeItem } from "../../types/OfficeItem";
import axiosInstance from "../../utils/AxiosInstance";

async function getApplicantAnalyticsItems(): Promise<ApplicantAnalyticsItem[]> {
  try {
    const response = await axiosInstance.get("/applicant_analytics_items");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function fetchJobCoachItems(): Promise<JobCoachItem[]> {
  try {
    const response = await axiosInstance.get("/job_coaches_list");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function fetchOfficeItems(): Promise<OfficeItem[]> {
  try {
    const response = await axiosInstance.get("/offices_list");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export default getApplicantAnalyticsItems;
