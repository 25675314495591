import React, { useState, useMemo } from "react";
import { Box, TextField, Button, Typography, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { createSendOut, editSendOut } from "./SendOutsRepository";
import MaijaModal from "../../../components/MaijaModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { SendOut } from "../../../types/SendOut";

interface CreateEditSendOutModalProps {
  open: boolean;
  onClose: () => void;
  isEditing: boolean;
  sendOut: SendOut | null;
  onSave: (sendOut: SendOut) => void;
}

const CreateEditSendOutModal: React.FC<CreateEditSendOutModalProps> = ({
  open,
  onClose,
  isEditing,
  sendOut,
  onSave,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [hasFile, setHasFile] = useState<boolean>(false);
  const [fileData, setFileData] = useState<string>("");

  const [titleError, setTitleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useMemo(() => {
    if (open) {
      setTitle(sendOut?.title || "");
      setDescription(sendOut?.description || "");
      setHasFile(sendOut?.hasFile || false);
      setFileData("");
      setTitleError(false);
    }
  }, [open, sendOut]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0] || null;

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        setFileData(reader.result as string);
        setHasFile(true);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setHasFile(false);
    }
  };

  const handleClearFile = () => {
    setHasFile(false);
    setFileData("");
  };

  const handleSubmit = async () => {
    if (title.trim().length === 0) {
      setTitleError(true);
      return;
    }

    setIsLoading(true);

    let newSendOut = {
      ...sendOut,
      title,
      description,
      fileData: fileData,
      hasFile: hasFile,
    } as SendOut;

    try {
      if (isEditing) {
        newSendOut = await editSendOut(newSendOut);
      } else {
        newSendOut = await createSendOut(newSendOut);
      }
      onSave(newSendOut);
    } catch {
      dispatch(
        showSnackbar({
          message: t(isEditing ? "createSendoutPage.editError" : "createSendoutPage.createError"),
          severity: "error",
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTitle(value);
    setTitleError(value.trim().length === 0);
  };

  return (
    <MaijaModal isOpen={open} handleClose={onClose}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">
          {isEditing ? t("createSendoutPage.editTitle") : t("createSendoutPage.createTitle")}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TextField
        fullWidth
        label={t("createSendoutPage.titleLabel")}
        value={title}
        onChange={handleTitleChange}
        error={titleError}
        helperText={titleError ? t("createSendoutPage.titleRequired") : ""}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label={t("createSendoutPage.descriptionLabel")}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" component="label">
        {hasFile ? t("createSendoutPage.changePDF") : t("createSendoutPage.uploadPDF")}
        <input type="file" accept="application/pdf" hidden onChange={handleFileChange} />
      </Button>
      {hasFile && (
        <Box mt={2} position="relative" width={100} height={100}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InsertDriveFileIcon fontSize="large" />
          </Box>
          <IconButton
            onClick={handleClearFile}
            size="small"
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              backgroundColor: "white",
              color: "black",
              "&:hover": { backgroundColor: "grey.300" },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading || title.trim().length === 0}
          startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
        >
          {isEditing ? t("createSendoutPage.saveChanges") : t("createSendoutPage.create")}
        </Button>
      </Box>
    </MaijaModal>
  );
};

export default CreateEditSendOutModal;
