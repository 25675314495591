import { JobCoachItem } from "../../types/JobCoachItem";
import axiosInstance from "../../utils/AxiosInstance";

export async function fetchJobCoachItems(): Promise<JobCoachItem[]> {
  try {
    const response = await axiosInstance.get("/job_coaches_list");
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}
