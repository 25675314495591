import React, { useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { TFunction } from "i18next";
import ClickableHeaderItem from "../../components/ClickableHeaderItem";
import AnalyticsTableItemComponent from "./AnalyticsTableItemComponent";
import { FixedSizeList as List } from "react-window";
import { getComparisonFunction } from "./AnalyticsComparisonFunction";

export interface AnalyticsTableProps {
  applicants: ApplicantAnalyticsItem[];
  t: TFunction;
}

const AnalyticsTableComponent: React.FC<AnalyticsTableProps> = ({ applicants, t }) => {
  const [sortKey, setSortKey] = useState<keyof ApplicantAnalyticsItem | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  const sortedApplicants = useMemo(() => {
    if (!applicants) return [];

    if (!sortKey) return applicants;

    const comparisonFunction = getComparisonFunction(sortKey, t);

    if (!comparisonFunction) return applicants;

    const sortedList = [...applicants].sort((a, b) => {
      const result = comparisonFunction(a, b);
      return sortOrder === "asc" ? result : -result;
    });

    return sortedList;
  }, [applicants, sortKey, sortOrder, t]);

  const handleSort = useCallback(
    (key: keyof ApplicantAnalyticsItem) => {
      let order: "asc" | "desc" = "asc";
      if (sortKey === key) {
        order = sortOrder === "asc" ? "desc" : "asc";
      }

      setSortKey(key);
      setSortOrder(order);
    },
    [sortKey, sortOrder],
  );

  const headerItems: { text: string; key: keyof ApplicantAnalyticsItem }[] = [
    { text: t("analyticsPage.fields.gender"), key: "gender" },
    { text: t("analyticsPage.fields.age"), key: "age" },
    { text: t("analyticsPage.fields.evaluationCriteria"), key: "evaluationCriteria" },
    { text: t("analyticsPage.fields.appliedJobsLast30Days"), key: "appliedJobsLast30Days" },
    { text: t("analyticsPage.fields.nonAppliedJobsCount"), key: "nonAppliedJobsCount" },
    { text: t("analyticsPage.fields.latestLogin"), key: "latestLogin" },
    { text: t("analyticsPage.fields.profileCompletion"), key: "profileCompletion" },
    { text: t("analyticsPage.fields.educationLevel"), key: "educationLevel" },
    { text: t("analyticsPage.fields.sfiLevel"), key: "sfiLevel" },
    { text: t("analyticsPage.fields.canTakeWorkImmediately"), key: "canTakeWorkImmediately" },
  ];

  const renderHeader = () => (
    <Box
      display="flex"
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        zIndex: 1,
        borderBottom: "1px solid #ccc",
      }}
    >
      {headerItems.map(({ text, key }) => (
        <Box width={250} p={1} key={key}>
          <ClickableHeaderItem
            text={text}
            onClick={() => handleSort(key)}
            sortOrder={sortKey === key ? sortOrder : undefined}
          />
        </Box>
      ))}
    </Box>
  );

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => (
    <div style={style}>
      <AnalyticsTableItemComponent applicant={sortedApplicants[index]} t={t} />
    </div>
  );

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box minWidth="1500px">
        {renderHeader()}
        <List height={500} itemCount={sortedApplicants.length} itemSize={60} width="100%">
          {Row}
        </List>
      </Box>
    </Box>
  );
};

export default React.memo(AnalyticsTableComponent);
