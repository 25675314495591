import { JobCoachReport } from "../../types/JobCoachReport";
import axiosInstance from "../../utils/AxiosInstance";
import { AxiosResponse } from "axios";

export const getJobCoachReport = async (jobCoachId: string, reportId: string): Promise<JobCoachReport> => {
  const response: AxiosResponse<JobCoachReport> = await axiosInstance.get("/job_coach/report", {
    params: {
      job_coach_id: jobCoachId,
      report_id: reportId,
    },
  });
  return response.data;
};

export async function getJobCoachReports(jobCoachId: string): Promise<JobCoachReport[]> {
  try {
    const response = await axiosInstance.get("/job_coach/reports", {
      params: {
        job_coach_id: jobCoachId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const getNewJobCoachReport = async (jobCoachId: string): Promise<JobCoachReport> => {
  const response: AxiosResponse<JobCoachReport> = await axiosInstance.get("/job_coach/report_create", {
    params: {
      job_coach_id: jobCoachId,
    },
  });
  return response.data;
};

export const sendJobCoachReportEmail = async (jobCoachId: string, reportId: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get("/job_coach/report/email", {
      params: {
        job_coach_id: jobCoachId,
        report_id: reportId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending job coach report email:", error);
    throw error;
  }
};
