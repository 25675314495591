import { ApplicantItem } from "./ApplicantItem";
import { JobCoachItem } from "./JobCoachItem";

export type JobCoachDetails = {
  jobCoachId: string;
  name: string;
  officeName: string;
  officeId: string;
  companyName: string;
  companyId: string;
  email: string;
  phoneNumber: string;
  numberApplicants: number;
  numberGotJob: number;
  numberAppliedJob: number;
  applicantItems: ApplicantItem[];
  profilePictureUrl: string;
};

export const jobCoachDetailsDetailsToJobCoachItem = (jobCoachDetails: JobCoachDetails): JobCoachItem => {
  return {
    id: jobCoachDetails.jobCoachId,
    fullName: jobCoachDetails.name,
    officeId: jobCoachDetails.officeId,
    numberApplicants: jobCoachDetails.numberApplicants,
    numberJobsLast30Days: jobCoachDetails.numberGotJob, // This is not correct, but not used at the moment
  };
};
