import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApplicantAnalyticsItem } from "../types/ApplicantAnalyticsItem";
import { JobCoachItem } from "../types/JobCoachItem";
import { OfficeItem } from "../types/OfficeItem";

interface AnalyticsPageState {
  applicants: ApplicantAnalyticsItem[];
  jobCoaches: JobCoachItem[];
  offices: OfficeItem[];
  selectedOffice: OfficeItem | null;
  selectedJobCoach: JobCoachItem | null;
}

const initialState: AnalyticsPageState = {
  applicants: [],
  jobCoaches: [],
  offices: [],
  selectedOffice: null,
  selectedJobCoach: null,
};

export const applicantListPageSlice = createSlice({
  name: "applicantList",
  initialState,
  reducers: {
    setAnalyticsApplicants: (state, action: PayloadAction<ApplicantAnalyticsItem[]>) => {
      state.applicants = action.payload;
    },
    setAnalyticsSelectedJobCoach: (state, action: PayloadAction<JobCoachItem | null>) => {
      state.selectedJobCoach = action.payload;
    },
    setAnalyticsSelectedOffice: (state, action: PayloadAction<OfficeItem | null>) => {
      state.selectedOffice = action.payload;
    },
    setJobCoaches: (state, action: PayloadAction<JobCoachItem[]>) => {
      state.jobCoaches = action.payload;
    },
    setOffices: (state, action: PayloadAction<OfficeItem[]>) => {
      state.offices = action.payload;
    },
    clearAnalyticsPage: (state) => {
      state.applicants = [];
      state.jobCoaches = [];
      state.offices = [];
      state.selectedOffice = null;
      state.selectedJobCoach = null;
    },
  },
});

export const {
  setAnalyticsApplicants,
  setAnalyticsSelectedJobCoach,
  setAnalyticsSelectedOffice,
  setJobCoaches,
  setOffices,
  clearAnalyticsPage,
} = applicantListPageSlice.actions;
export const applicantListPage = (state: RootState) => state.applicantListPageSlice;
export default applicantListPageSlice.reducer;
