import React, { useCallback, useMemo, useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setAnalyticsApplicants,
  setAnalyticsSelectedJobCoach,
  setAnalyticsSelectedOffice,
  setJobCoaches,
  setOffices,
} from "../../reducers/analyticsPageSlice";
import getApplicantAnalyticsItems, { fetchJobCoachItems, fetchOfficeItems } from "./AnalyticsRepository";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import { useTranslation } from "react-i18next";
import AnalyticsBarChartComponent from "./AnalyticsBarChartComponent";
import FilterSelectionComponent from "./FilterSelectionComponent";
import AnalyticsTableComponent from "./AnalyticsTableComponent";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { analyticsDataFields } from "./AnalyticsDataFields";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TableRows } from "@mui/icons-material";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "react-query";
import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { OfficeItem } from "../../types/OfficeItem";
import { JobCoachItem } from "../../types/JobCoachItem";

export const AnalyticsPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const offices = useAppSelector((state) => state.analyticsPageSlice.offices);
  const jobCoaches = useAppSelector((state) => state.analyticsPageSlice.jobCoaches);
  const fullApplicantList = useAppSelector((state) => state.analyticsPageSlice.applicants);
  const selectedOffice = useAppSelector((state) => state.analyticsPageSlice.selectedOffice);
  const selectedJobCoach = useAppSelector((state) => state.analyticsPageSlice.selectedJobCoach);

  const handleChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedColumn(event.target.value as string);
  }, []);

  // Fetch job coach data
  const {
    data: jobCoachData,
    isLoading: isJobCoachLoading,
    error: jobCoachError,
  } = useQuery<JobCoachItem[]>({
    queryKey: ["jobCoaches"],
    queryFn: fetchJobCoachItems,
  });

  // Fetch office data
  const {
    data: officeData,
    isLoading: isOfficeLoading,
    error: officeError,
  } = useQuery<OfficeItem[]>({
    queryKey: ["offices"],
    queryFn: fetchOfficeItems,
  });

  // Fetch applicant data
  const {
    data: applicantData,
    isLoading: isApplicantLoading,
    error: applicantError,
  } = useQuery<ApplicantAnalyticsItem[]>({
    queryKey: ["applicants"],
    queryFn: getApplicantAnalyticsItems,
  });

  const isLoading = isJobCoachLoading || isOfficeLoading || isApplicantLoading;
  const hasError = jobCoachError || officeError || applicantError;

  const [selectedColumn, setSelectedColumn] = useState<string>("sfiLevel");
  const [filters, setFilters] = useState<{ [key: string]: string | null }>({});

  const handleFilterChange = useCallback((fieldName: string, value: string | null) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  }, []);

  const clearAllFilters = useCallback(() => {
    setFilters({});
    dispatch(setAnalyticsSelectedOffice(null));
    dispatch(setAnalyticsSelectedJobCoach(null));
  }, [dispatch]);

  const filteredApplicants = useMemo(() => {
    const isFilterActive = selectedOffice || selectedJobCoach || Object.keys(filters).some((key) => filters[key]);

    if (!isFilterActive) {
      return fullApplicantList;
    }

    return fullApplicantList.filter((applicant) => {
      if (selectedOffice && applicant.officeId !== selectedOffice.id) return false;
      if (selectedJobCoach && applicant.jobCoachId !== selectedJobCoach.id) return false;

      for (const field of analyticsDataFields) {
        const selectedValue = filters[field.name];
        if (selectedValue && field.filter) {
          if (!field.filter(applicant, selectedValue as string)) {
            return false;
          }
        }
      }

      return true;
    });
  }, [selectedOffice, selectedJobCoach, fullApplicantList, filters]);

  useEffect(() => {
    if (selectedOffice && selectedJobCoach && selectedJobCoach.officeId !== selectedOffice.id) {
      dispatch(setAnalyticsSelectedJobCoach(null));
    }
  }, [selectedOffice, selectedJobCoach, dispatch]);

  useEffect(() => {
    if (Array.isArray(jobCoachData)) {
      dispatch(setJobCoaches(jobCoachData));
    }
    if (Array.isArray(officeData)) {
      dispatch(setOffices(officeData));
    }
    if (Array.isArray(applicantData)) {
      dispatch(setAnalyticsApplicants(applicantData));
    }
  }, [dispatch, jobCoachData, officeData, applicantData]);

  const getData = useMemo(() => {
    const fieldConfig = analyticsDataFields.find((field) => field.name === selectedColumn);
    if (!fieldConfig) return [];

    const groupedData: Record<string, number> = {};

    fieldConfig.options.forEach((option) => {
      groupedData[option] = 0;
    });

    filteredApplicants.forEach((applicant) => {
      const key = fieldConfig.groupBy(applicant);

      if (typeof key === "string") {
        groupedData[key] = (groupedData[key] || 0) + 1;
      }
    });

    const dataArray = Object.keys(groupedData).map((key) => ({
      type: t(`analyticsPage.fields.${key}`, key),
      count: groupedData[key],
    }));

    return dataArray;
  }, [filteredApplicants, selectedColumn, t]);

  if (isLoading) {
    return <MaijaLoadingPage />;
  }

  if (hasError) {
    dispatch(showSnackbar({ message: t("analyticsPage.failedToFetchData"), severity: "error" }));
    return <div>{t("analyticsPage.failedToFetchData")}</div>;
  }

  return (
    <Paper sx={{ mb: 4, p: 2 }}>
      <ReportButtons />

      <FilterSelectionComponent
        offices={offices}
        jobCoaches={jobCoaches}
        selectedColumn={selectedColumn}
        handleChange={handleChange}
        filters={filters}
        handleFilterChange={handleFilterChange}
        clearAllFilters={clearAllFilters}
        t={t}
      />

      <AnalyticsBarChartComponent data={getData} />

      <Accordion sx={{ mb: 40 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filters-content" id="filters-header">
          <TableRows sx={{ mr: 3, fontSize: 32, color: "rgba(0, 0, 0, 0.54)", alignSelf: "center" }} />
          <Typography variant="h6">{t("analyticsPage.tableTitle")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AnalyticsTableComponent applicants={filteredApplicants} t={t} />
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

const ReportButtons: React.FC = () => {
  const { t } = useTranslation();

  const handleBusinessReportClick = () => {};
  const handleRegionalReportClick = () => {};
  const handleFilteredReportClick = () => {};

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      position="fixed"
      bottom="60px"
      right="60px"
      zIndex={1000}
      gap={2}
    >
      <Fab variant="extended" color="secondary" onClick={handleBusinessReportClick}>
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("analyticsPage.businessReport")}</Typography>
      </Fab>

      <Fab variant="extended" color="secondary" onClick={handleRegionalReportClick}>
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("analyticsPage.regionalReport")}</Typography>
      </Fab>

      <Fab variant="extended" color="secondary" onClick={handleFilteredReportClick}>
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("analyticsPage.filteredReport")}</Typography>
      </Fab>
    </Box>
  );
};

export default AnalyticsPage;
