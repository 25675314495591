import React, { useEffect } from "react";

import { JobCoachReportListItem } from "../../types/JobCoachReport";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ReportListComponent } from "../../components/ReportsListComponent";
import { jobCoachDetailsPageSlice } from "../../reducers/jobCoachDetailsPageSlice";
import { getJobCoachReports } from "./JobCoachReportsRepository";

interface JobCoachReportsComponentProps {
  jobCoachId: string;
  onShowReport: (reportId: string) => void;
}

export const JobCoachReportsComponent: React.FC<JobCoachReportsComponentProps> = ({ jobCoachId, onShowReport }) => {
  const dispatch = useAppDispatch();
  const reports = useAppSelector((state) => state.jobCoachDetailsPageSlice.reportListItemsMap[jobCoachId] || []);

  useEffect(() => {
    getJobCoachReports(jobCoachId).then((data) => {
      const sortedData = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      dispatch(
        jobCoachDetailsPageSlice.actions.setJobCoachDetailsReportsList({
          jobCoachId,
          reports: sortedData,
        }),
      );
    });
  }, [jobCoachId, dispatch]);

  return (
    <ReportListComponent<JobCoachReportListItem>
      reports={reports}
      onShowReport={onShowReport}
      reportName="jobCoachDetails"
    />
  );
};
