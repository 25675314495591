import { CompanySettings } from "../../types/CompanySettings";
import { User } from "../../types/User";
import axiosInstance from "../../utils/AxiosInstance";

export interface LoginData {
  email: string;
  password: string;
}

export function login(data: LoginData): Promise<User> {
  return axiosInstance
    .post<User>("/login", {
      email: data.email,
      password: data.password,
    })
    .then((response) => {
      return response.data;
    });
}

export function ping(): Promise<string> {
  return axiosInstance.get<string>("/test").then((response) => {
    return response.data;
  });
}

export function getSettings(companyId: string): Promise<CompanySettings> {
  return axiosInstance
    .get<CompanySettings>(`/company_settings_login`, {
      params: { company_id: companyId },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching company settings:", error);
      throw error;
    });
}
