import React, { useState, useMemo, useCallback } from "react";
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FilterList, Clear } from "@mui/icons-material";
import { OfficeItem } from "../../types/OfficeItem";
import { JobCoachItem } from "../../types/JobCoachItem";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAnalyticsSelectedOffice, setAnalyticsSelectedJobCoach } from "../../reducers/analyticsPageSlice";
import { TFunction } from "i18next";
import { analyticsDataFields } from "./AnalyticsDataFields";

interface FilterSelectionProps {
  offices: OfficeItem[];
  jobCoaches: JobCoachItem[];
  selectedColumn: string;
  handleChange: (event: SelectChangeEvent<string>) => void;
  t: TFunction;
  filters: { [key: string]: string | null };
  handleFilterChange: (fieldName: string, value: string | null) => void;
  clearAllFilters: () => void;
}

const FilterSelectionComponent: React.FC<FilterSelectionProps> = ({
  offices,
  jobCoaches,
  selectedColumn,
  handleChange,
  t,
  filters,
  handleFilterChange,
  clearAllFilters,
}) => {
  const dispatch = useAppDispatch();
  const selectedOffice = useAppSelector((state) => state.analyticsPageSlice.selectedOffice);
  const selectedJobCoach = useAppSelector((state) => state.analyticsPageSlice.selectedJobCoach);

  const [selectedOfficeValid, setSelectedOfficeValid] = useState(true);
  const [selectedJobCoachValid, setSelectedJobCoachValid] = useState(true);

  const filteredJobCoaches = useMemo(() => {
    if (!selectedOffice) return jobCoaches;
    return jobCoaches.filter((jobCoach) => jobCoach.officeId === selectedOffice.id);
  }, [selectedOffice, jobCoaches]);

  const handleOfficeChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedId = event.target.value;
      const selectedOfficeItem = offices.find((office) => office.id === selectedId) || null;

      if (!selectedOfficeValid) setSelectedOfficeValid(selectedOfficeItem != null);
      dispatch(setAnalyticsSelectedOffice(selectedOfficeItem));

      dispatch(setAnalyticsSelectedJobCoach(null));
    },
    [dispatch, offices, selectedOfficeValid],
  );

  const handleJobCoachChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const selectedId = event.target.value;
      const selectedCoach = filteredJobCoaches.find((coach) => coach.id === selectedId) || null;

      if (!selectedJobCoachValid) setSelectedJobCoachValid(selectedCoach != null);
      dispatch(setAnalyticsSelectedJobCoach(selectedCoach));
    },
    [dispatch, filteredJobCoaches, selectedJobCoachValid],
  );

  return (
    <Paper sx={{ mb: 3, p: 4 }}>
      <Accordion sx={{ mt: 3 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filters-content" id="filters-header">
          <FilterList
            sx={{
              mr: 2,
              fontSize: 32,
              color: "rgba(0, 0, 0, 0.54)",
              alignSelf: "center",
            }}
          />
          <Typography variant="h6">{t("analyticsPage.additionalFilters")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth error={!selectedOfficeValid}>
                <InputLabel htmlFor="office-select" sx={{ display: "flex", alignItems: "center" }}>
                  {t("analyticsPage.selectOffice")}
                </InputLabel>
                <Select
                  labelId="office-select-label"
                  id="office-select"
                  value={selectedOffice ? selectedOffice.id : ""}
                  label={t("analyticsPage.selectOffice")}
                  onChange={handleOfficeChange}
                >
                  <MenuItem value="">
                    <em>{t("analyticsPage.all")}</em>
                  </MenuItem>
                  {offices.map((office) => (
                    <MenuItem key={office.id} value={office.id}>
                      {office.name}
                    </MenuItem>
                  ))}
                </Select>
                {!selectedOfficeValid && (
                  <Typography variant="caption" color="error">
                    {t("analyticsPage.selectOffice")}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor="job-coach-select" sx={{ display: "flex", alignItems: "center" }}>
                  {t("analyticsPage.selectJobCoach")}
                </InputLabel>
                <Select
                  labelId="job-coach-select-label"
                  id="job-coach-select"
                  value={selectedJobCoach ? selectedJobCoach.id : ""}
                  label={t("analyticsPage.selectJobCoach")}
                  onChange={handleJobCoachChange}
                  error={!selectedJobCoachValid}
                >
                  <MenuItem value="">
                    <em>{t("analyticsPage.all")}</em>
                  </MenuItem>
                  {filteredJobCoaches.map((coach) => (
                    <MenuItem key={coach.id} value={coach.id}>
                      {coach.fullName}
                    </MenuItem>
                  ))}
                </Select>
                {!selectedJobCoachValid && (
                  <Typography variant="caption" color="error">
                    {t("analyticsPage.selectJobCoach")}
                  </Typography>
                )}
              </FormControl>
            </Grid>

            {analyticsDataFields
              .filter((field) => field.labelKey && field.filter)
              .map((field) => (
                <Grid item xs={12} sm={6} md={3} key={field.name}>
                  <FormControl fullWidth>
                    <InputLabel>{t(field.labelKey)}</InputLabel>
                    <Select
                      value={filters[field.name] || ""}
                      label={t(field.labelKey)}
                      onChange={(e) => handleFilterChange(field.name, e.target.value || null)}
                    >
                      <MenuItem value="">
                        <em>{t("analyticsPage.all")}</em>
                      </MenuItem>
                      {field.options.map((option) => (
                        <MenuItem key={option} value={option}>
                          {t(`analyticsPage.fields.${option}`, option)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ))}
          </Grid>

          <Button variant="outlined" startIcon={<Clear />} color="error" onClick={clearAllFilters} sx={{ mt: 3 }}>
            {t("analyticsPage.clearFilters")}
          </Button>
        </AccordionDetails>
      </Accordion>
      <FormControl fullWidth sx={{ mt: 3 }}>
        <InputLabel id="select-column-label">{t("analyticsPage.fields.selectColumn")}</InputLabel>
        <Select
          labelId="select-column-label"
          value={selectedColumn}
          onChange={handleChange}
          label={t("analyticsPage.fields.selectColumn")}
        >
          {analyticsDataFields.map((field) => (
            <MenuItem key={field.name} value={field.name}>
              {t(field.labelKey)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  );
};

export default FilterSelectionComponent;
