import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { TFunction } from "i18next";

type ApplicantValue = string | number | boolean | null | undefined;

type ComparisonFunction = (a: ApplicantAnalyticsItem, b: ApplicantAnalyticsItem) => number;

export const getComparisonFunction = (
  sortKey: keyof ApplicantAnalyticsItem | null,
  t: TFunction,
): ComparisonFunction | null => {
  if (!sortKey) return null;

  const fieldTypes: Partial<Record<keyof ApplicantAnalyticsItem, "number" | "string" | "boolean">> = {
    gender: "string",
    age: "number",
    evaluationCriteria: "string",
    appliedJobsLast30Days: "number",
    nonAppliedJobsCount: "number",
    latestLogin: "string",
    profileCompletion: "number",
    educationLevel: "string",
    sfiLevel: "string",
    canTakeWorkImmediately: "boolean",
  };

  const fieldType = fieldTypes[sortKey];

  if (!fieldType) {
    return null;
  }

  const nullSafeValue = (value: ApplicantValue): ApplicantValue =>
    value !== null && value !== undefined ? value : null;

  let comparisonFunction: ComparisonFunction;

  switch (fieldType) {
    case "number":
      comparisonFunction = (a, b) => {
        const valueA = nullSafeValue(a[sortKey]) as number | null;
        const valueB = nullSafeValue(b[sortKey]) as number | null;

        if (valueA === null && valueB === null) return 0;
        if (valueA === null) return 1;
        if (valueB === null) return -1;

        return valueA - valueB;
      };
      break;

    case "string":
      comparisonFunction = (a, b) => {
        const valueA = nullSafeValue(a[sortKey]) as string | null;
        const valueB = nullSafeValue(b[sortKey]) as string | null;

        if (valueA === null && valueB === null) return 0;
        if (valueA === null) return 1;
        if (valueB === null) return -1;

        const translatedA = t(`${sortKey}Enum.${valueA}`);
        const translatedB = t(`${sortKey}Enum.${valueB}`);

        return translatedA.localeCompare(translatedB);
      };
      break;

    case "boolean":
      comparisonFunction = (a, b) => {
        const valueA = nullSafeValue(a[sortKey]) as boolean | null;
        const valueB = nullSafeValue(b[sortKey]) as boolean | null;

        if (valueA === null && valueB === null) return 0;
        if (valueA === null) return 1;
        if (valueB === null) return -1;
        if (valueA === valueB) return 0;

        return valueA ? -1 : 1;
      };
      break;

    default:
      comparisonFunction = (a, b) => {
        const valueA = String(nullSafeValue(a[sortKey]));
        const valueB = String(nullSafeValue(b[sortKey]));
        return valueA.localeCompare(valueB);
      };
      break;
  }

  return comparisonFunction;
};
