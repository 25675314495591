import axiosInstance from "../../utils/AxiosInstance";

export function forgotPassword(email: string): Promise<boolean> {
  return axiosInstance
    .post<boolean>("/forgot-password", {
      email: email,
    })
    .then((response) => {
      return response.data;
    });
}
