import React, { useState, createContext, ReactNode, useEffect } from "react";
import { User } from "../types/User";
import { AuthContextType } from "../types/AuthContextType";
import { useAppDispatch } from "../hooks";
import { clearLoggedInUser, setLoggedInUser } from "../reducers/userSlice";
import axiosInstance from "../utils/AxiosInstance";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  loading: true,
  setUser: (_user: User | null) => {},
  setIsAuthenticated: (_isAuthenticated: boolean) => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");

    const verifySession = async () => {
      try {
        const response = await axiosInstance.get("/current-user", {
          withCredentials: true,
        });

        if (response.status === 200 && response.data) {
          const serverUser: User = response.data;
          setUser(serverUser);
          setIsAuthenticated(true);
          dispatch(setLoggedInUser(serverUser));
          localStorage.setItem("currentUser", JSON.stringify(serverUser));
        } else {
          localStorage.removeItem("currentUser");
          setUser(null);
          setIsAuthenticated(false);
          dispatch(clearLoggedInUser());
        }
      } catch (error) {
        console.error("Session verification failed", error);
        localStorage.removeItem("currentUser");
        setUser(null);
        setIsAuthenticated(false);
        dispatch(clearLoggedInUser());
      } finally {
        setLoading(false);
      }
    };

    if (storedUser) {
      verifySession();
    } else {
      setUser(null);
      setIsAuthenticated(false);
      dispatch(clearLoggedInUser());
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "currentUser") {
        if (event.newValue) {
          const parsedUser = JSON.parse(event.newValue);
          setUser(parsedUser);
          setIsAuthenticated(true);
          dispatch(setLoggedInUser(parsedUser));
        } else {
          setUser(null);
          setIsAuthenticated(false);
          dispatch(clearLoggedInUser());
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  const authContextValue = {
    user,
    isAuthenticated,
    loading,
    setUser: (newUser: User | null) => {
      setUser(newUser);
      if (newUser) {
        localStorage.setItem("currentUser", JSON.stringify(newUser));
        setIsAuthenticated(true);
        dispatch(setLoggedInUser(newUser));
      } else {
        localStorage.removeItem("currentUser");
        setIsAuthenticated(false);
        dispatch(clearLoggedInUser());
      }
    },
    setIsAuthenticated,
  };

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
