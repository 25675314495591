import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Card, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import MaijaModal from "../components/MaijaModal";
import { MaijaA4Paper } from "../components/MaijaA4Paper";
import { Download, Email } from "@mui/icons-material";
import { downloadBase64File } from "../utils/FileUtil";
import { showSnackbar } from "../reducers/snackbarSlice";
import { useAppDispatch } from "../hooks";
import { BaseReport } from "../types/Report";

enum ModeType {
  GENERATE = "GENERATE",
  LOAD_EXISTING = "LOAD_EXISTING",
}

export class PreviewModalMode {
  static GENERATE = new PreviewModalMode(ModeType.GENERATE);

  static LOAD_EXISTING(reportId: string) {
    return new PreviewModalMode(ModeType.LOAD_EXISTING, reportId);
  }

  mode: ModeType;
  reportId?: string;

  private constructor(mode: ModeType.GENERATE | ModeType.LOAD_EXISTING, reportId?: string) {
    this.mode = mode;
    this.reportId = reportId;
  }
}

interface ReportPreviewModalProps<T extends BaseReport> {
  isOpen: boolean;
  handleClose: () => void;
  reportMode: PreviewModalMode;
  fetchReport: (reportId?: string) => Promise<T>;
  sendReportEmail: (reportId: string) => Promise<void>;
  reportName: string;
  report: T | undefined;
  setReport: React.Dispatch<React.SetStateAction<T | undefined>>;
  additionalActionContent?: React.ReactNode;
}

function ReportPreviewModal<T extends BaseReport & { pdfEncoded: string; thumbnailsEncoded: string[] }>({
  isOpen,
  handleClose,
  reportMode,
  fetchReport,
  sendReportEmail,
  reportName,
  report,
  setReport,
  additionalActionContent,
}: ReportPreviewModalProps<T>) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      const fetchReportData = async () => {
        try {
          let newReport: T;
          if (reportMode.mode === ModeType.LOAD_EXISTING) {
            newReport = await fetchReport(reportMode.reportId!);
          } else if (reportMode.mode === ModeType.GENERATE) {
            newReport = await fetchReport();
          } else {
            throw new Error("Invalid report mode");
          }
          setReport(newReport);
        } catch (error) {
          dispatch(
            showSnackbar({
              message: t(`reportPreview.errorLoading`),
              severity: "error",
            }),
          );
          handleClose();
        } finally {
          setIsLoading(false);
        }
      };

      fetchReportData();
    }
  }, [isOpen, dispatch, handleClose, reportMode.mode, reportMode.reportId, setReport, t]);

  const handleDownload = () => {
    if (!report) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } else {
      const fileName = `${reportName}_report`;
      const pdfEncoded = report.pdfEncoded;
      downloadBase64File(pdfEncoded, fileName);
    }
  };

  const handleEmail = async () => {
    if (!report) return;

    try {
      setIsEmailSending(true);
      await sendReportEmail(report.id!);
      dispatch(
        showSnackbar({
          message: t(`reportPreview.emailSent`),
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } finally {
      setIsEmailSending(false);
    }
  };

  const thumbnails = report ? report.thumbnailsEncoded : [];

  return (
    <MaijaModal isOpen={isOpen} handleClose={handleClose} width="70%" maxHeight="95vh" overflowY="visible" p={0}>
      <Box sx={{ display: "flex", height: "90vh", maxHeight: "95vh" }}>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            alignItems: "center",
            pt: 5,
            pb: 5,
            pl: 5,
          }}
        >
          <>
            {thumbnails.map((thumbnail, index) => {
              return (
                <Box key={index} sx={{ mb: 2, width: "100%" }}>
                  <MaijaA4Paper loading={isLoading}>
                    {!isLoading && (
                      <img
                        src={`data:image/jpeg;base64,${thumbnail}`}
                        alt={`Report Page ${index + 1}`}
                        style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                      />
                    )}
                  </MaijaA4Paper>
                </Box>
              );
            })}
            {isLoading && <MaijaA4Paper loading={true} />}
          </>
        </Box>
        <Box
          sx={{
            width: "300px",
            p: 5,
            overflowY: "auto",
          }}
        >
          <Card sx={{ p: 5 }}>
            <Typography variant="body1" sx={{ mb: 3, fontWeight: "bold", fontSize: "1.1rem" }}>
              {t(`reportPreview.manage`)}
            </Typography>

            <Button
              variant="text"
              color="inherit"
              startIcon={<Download />}
              onClick={handleDownload}
              fullWidth
              disabled={!report}
              sx={{ justifyContent: "flex-start", pl: 2, mb: 2 }}
            >
              {t(`reportPreview.download`)}
            </Button>

            <Button
              variant="text"
              color="inherit"
              startIcon={isEmailSending ? <CircularProgress size={20} /> : <Email />}
              onClick={handleEmail}
              fullWidth
              disabled={!report || isEmailSending}
              sx={{ justifyContent: "flex-start", pl: 2 }}
            >
              {isEmailSending ? t(`reportPreview.sending`) : t(`reportPreview.email`)}
            </Button>
          </Card>

          {additionalActionContent}
        </Box>
      </Box>
    </MaijaModal>
  );
}

export default ReportPreviewModal;
