import React, { useEffect, useState, useMemo, useCallback, useContext } from "react";
import { useQuery } from "react-query";
import getApplicantItems from "./ApplicantsListRepository";
import {
  Box,
  Card,
  Container,
  Typography,
  Fab,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableContainer,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { COMPANY_CREATE_ROUTE, navigateToApplicantDetails } from "../../Routes";
import { CREATE_APPLICANT_ROUTE } from "../../Routes";
import { ApplicantItem } from "../../types/ApplicantItem";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setApplicants } from "../../reducers/applicantListPageSlice";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";
import MaijaContainer from "../../components/MaijaContainer";
import { formatDateToString } from "../../utils/DateUtils";
import { useTranslation } from "react-i18next";
import ColumnSelectionButton from "./ColumnSelectionButton";
import { SecurityLevel } from "../../types/User";
import { setCompanyCreateSelectedTab } from "../../reducers/companyCreatePageSlice";
import { CompanyCreatePageTab } from "../company-create/CompanyCreatePage";
import { SentimentDissatisfied } from "@mui/icons-material";
import MaijaEmptyState from "../../components/MaijaEmptyState";
import SearchIcon from "@mui/icons-material/Search";
import { MaijaTableHeader, MaijaTableRow, MaijaTableColumn } from "../../components/MaijaTable";
import { AuthContext } from "../../providers/AuthProvider";

export const ApplicantsListPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");
  const { user: loggedInUser } = useContext(AuthContext);
  const displayedColumns = useAppSelector((state) => state.applicantListPageSlice.displayedColumns);

  const jobCoachId = loggedInUser?.jobCoachId || "";

  const { data: applicants = [], isLoading } = useQuery(
    ["applicants", jobCoachId],
    () => getApplicantItems(jobCoachId),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    },
  );

  useEffect(() => {
    dispatch(setApplicants(applicants));
  }, [applicants, dispatch]);

  const filteredApplicants = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return applicants.filter((applicant) => {
      return (
        (applicant.fullName && applicant.fullName.toLowerCase().includes(query)) ||
        (applicant.evaluationCriteria && applicant.evaluationCriteria.toLowerCase().includes(query)) ||
        (applicant.jobCoachName && applicant.jobCoachName.toLowerCase().includes(query))
      );
    });
  }, [applicants, searchQuery]);

  if (isLoading && applicants.length === 0) {
    return (
      <Container style={{ height: "100%" }}>
        <MaijaLoadingPage isFullscreen={true} />
      </Container>
    );
  }

  if (applicants.length === 0) {
    return (
      <Container>
        <ApplicantsListEmptyState />
      </Container>
    );
  }

  return (
    <MaijaContainer>
      <Fab
        variant="extended"
        color="secondary"
        style={{
          position: "fixed",
          bottom: "60px",
          right: "60px",
        }}
        onClick={() => {
          if (loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin) {
            dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.ApplicantTab));
            navigate(COMPANY_CREATE_ROUTE.path);
          } else {
            navigate(CREATE_APPLICANT_ROUTE.path);
          }
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("applicantsList.addApplicant")}</Typography>
      </Fab>

      <Typography variant="h4" gutterBottom sx={{ ml: 5 }}>
        {t("applicantsList.yourApplicants")}
      </Typography>

      <Paper sx={{ pt: 2 }}>
        <Box sx={{ my: 2, mb: 30, mx: 5, pb: 5 }}>
          <TextField
            label={t("applicantsList.search")}
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            sx={{ mb: 3 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <ApplicantList applicants={filteredApplicants} displayedColumns={displayedColumns} />
        </Box>
      </Paper>
    </MaijaContainer>
  );
};

type ApplicantListProps = {
  applicants: ApplicantItem[];
  displayedColumns: string[];
};

export const ApplicantList: React.FC<ApplicantListProps> = ({ applicants, displayedColumns }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sortFunction, setSortFunction] = useState<((a: ApplicantItem, b: ApplicantItem) => number) | null>(null); // eslint-disable-line

  const handleSort = useCallback(
    (sortFunc: (a: ApplicantItem, b: ApplicantItem) => number) => {
      if (sortFunction === sortFunc) {
        const reversedSort = (a: ApplicantItem, b: ApplicantItem) => -sortFunc(a, b);
        setSortFunction(() => reversedSort);
      } else {
        setSortFunction(() => sortFunc);
      }
    },
    [sortFunction],
  );

  const sortedApplicants = useMemo(() => {
    if (sortFunction) {
      return [...applicants].sort(sortFunction);
    }
    return applicants;
  }, [applicants, sortFunction]);

  const applicantsWithJob = useMemo(() => sortedApplicants.filter((applicant) => applicant.hasJob), [sortedApplicants]);
  const applicantsWithoutJob = useMemo(
    () => sortedApplicants.filter((applicant) => !applicant.hasJob),
    [sortedApplicants],
  );

  const allColumns: MaijaTableColumn<ApplicantItem>[] = useMemo(
    () => [
      {
        key: "fullName",
        label: t("applicantsList.name"),
        onSort: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      {
        key: "evaluationCriteria",
        label: t("applicantsList.evaluationCriteria"),
        onSort: (a, b) => (a.evaluationCriteria || "").localeCompare(b.evaluationCriteria || ""),
      },
      {
        key: "appliedJobsLast30Days",
        label: t("applicantsList.appliedJobs"),
        onSort: (a, b) => a.appliedJobsLast30Days - b.appliedJobsLast30Days,
      },
      {
        key: "nonAppliedJobsCount",
        label: t("applicantsList.nonAppliedJobs"),
        onSort: (a, b) => a.nonAppliedJobsCount - b.nonAppliedJobsCount,
      },
      {
        key: "latestLogin",
        label: t("applicantsList.latestLogin"),
        onSort: (a, b) => {
          if (!a.latestLogin && b.latestLogin) {
            return 1;
          } else if (a.latestLogin && !b.latestLogin) {
            return -1;
          } else if (!a.latestLogin && !b.latestLogin) {
            return 0;
          }
          return a.latestLogin.localeCompare(b.latestLogin);
        },
        renderCell: (item) => formatDateToString(item.latestLogin),
      },
      {
        key: "profileCompletion",
        label: t("applicantsList.profileCompletion"),
        onSort: (a, b) => (Number(a.profileCompletion) || 0) - (Number(b.profileCompletion) || 0),
        renderCell: (item) => `${Number(item.profileCompletion) * 100}%`,
      },
      {
        key: "educationLevel",
        label: t("applicantsList.educationLevel"),
        onSort: (a, b) => (a.educationLevel || "").localeCompare(b.educationLevel || ""),
        renderCell: (item) => t(`educationLevelsEnum.${item.educationLevel}`),
      },
      {
        key: "sfiLevel",
        label: t("applicantsList.sfiLevel"),
        onSort: (a, b) => (a.sfiLevel || "").localeCompare(b.sfiLevel || ""),
        renderCell: (item) => t(`sfiLevelsEnum.${item.sfiLevel}`),
      },
      {
        key: "canTakeWorkImmediately",
        label: t("applicantsList.canTakeWorkImmediately"),
        onSort: (a, b) =>
          a.canTakeWorkImmediately === b.canTakeWorkImmediately ? 0 : a.canTakeWorkImmediately ? -1 : 1,
        renderCell: (item) => (item.canTakeWorkImmediately ? t("applicantsList.yes") : t("applicantsList.no")),
      },
      {
        key: "jobCoachName",
        label: t("applicantsList.jobCoach"),
        onSort: (a, b) => (a.jobCoachName || "").localeCompare(b.jobCoachName || ""),
      },
    ],
    [t],
  );

  const columns = useMemo(
    () => allColumns.filter((column) => displayedColumns.includes(column.key)),
    [allColumns, displayedColumns],
  );

  const renderTable = useCallback(
    (applicantsList: ApplicantItem[], emptyMessage: string) =>
      applicantsList.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <MaijaTableHeader columns={columns} handleSort={handleSort} />
            <TableBody>
              {applicantsList.map((applicant) => (
                <MaijaTableRow
                  key={applicant.id}
                  item={applicant}
                  columns={columns}
                  onClick={() => navigateToApplicantDetails(navigate, applicant.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <MaijaEmptyState message={emptyMessage} />
      ),
    [columns, handleSort, navigate],
  );

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
        <Typography variant="h6">{t("applicantsList.applicantsWithoutJob")}</Typography>
        <ColumnSelectionButton />
      </Box>
      {renderTable(applicantsWithoutJob, t("applicantsList.noApplicantsWithoutJob"))}

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ m: 2 }}>
        <Typography variant="h6">{t("applicantsList.applicantsWithJob")}</Typography>
        <ColumnSelectionButton />
      </Box>
      {renderTable(applicantsWithJob, t("applicantsList.noApplicantsWithJob"))}
    </Box>
  );
};

export const ApplicantsListEmptyState = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user: loggedInUser } = useContext(AuthContext);

  const handleCreateApplicantButton = useCallback(() => {
    if (loggedInUser?.securityLevel === SecurityLevel.CompanyAdmin) {
      dispatch(setCompanyCreateSelectedTab(CompanyCreatePageTab.ApplicantTab));
      navigate(COMPANY_CREATE_ROUTE.path);
    } else {
      navigate(CREATE_APPLICANT_ROUTE.path);
    }
  }, [loggedInUser, dispatch, navigate]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Card sx={{ m: 15, p: 20 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <SentimentDissatisfied style={{ fontSize: 200 }} />
          <Typography variant="h4" gutterBottom>
            {t("applicantsList.noApplicants")}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCreateApplicantButton} sx={{ marginTop: 5 }}>
            {t("applicantsList.createApplicant")}
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

export default ApplicantsListPage;
