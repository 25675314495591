// components/ApplicantReportPreviewModal.tsx
import React, { useState } from "react";
import {
  getApplicantReport,
  getNewApplicantReport,
  sendApplicantReportEmail,
  generateApplicantActionPlan,
} from "./ApplicantReportsRepository";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Typography, Box, Card, FormControl, Select, MenuItem } from "@mui/material";
import { Create } from "@mui/icons-material";
import Flag from "react-world-flags";
import ReportPreviewModal, { PreviewModalMode } from "../../components/ReportPreviewModal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ApplicantReport } from "../../types/ApplicantReport";
import ApplicationLanguage, { getLanguageByCode, SUPPORTED_LANGUAGES } from "../../types/ApplicationLanguage";
import { applicantDetailsPageSlice } from "../../reducers/applicantDetailsPageSlice";
import { downloadBase64File } from "../../utils/FileUtil";
import { showSnackbar } from "../../reducers/snackbarSlice";

interface ApplicantReportPreviewModalProps {
  isOpen: boolean;
  applicantId: string;
  reportMode: PreviewModalMode;
  handleClose: () => void;
}

const ApplicantReportPreviewModal: React.FC<ApplicantReportPreviewModalProps> = ({
  isOpen,
  applicantId,
  reportMode,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [applicantReport, setApplicantReport] = useState<ApplicantReport | undefined>(undefined);
  const [isGeneratingActionPlan, setGeneratingActionPlan] = useState(false);
  const [language, setLanguage] = useState<ApplicationLanguage>(getLanguageByCode("sv"));

  const applicant = useAppSelector((state) =>
    applicantId ? state.applicantDetailsPageSlice.applicantsMap[applicantId] : undefined,
  );

  const fetchReport = async (reportId?: string): Promise<ApplicantReport> => {
    if (reportId) {
      const newReport = await getApplicantReport(applicantId, reportId);
      return newReport;
    } else {
      const newReport = await getNewApplicantReport(applicantId);
      dispatch(
        applicantDetailsPageSlice.actions.addApplicantDetailsReportsList({
          applicantId: applicantId,
          report: { ...newReport },
        }),
      );
      return newReport;
    }
  };

  const sendReportEmail = async (reportId: string): Promise<void> => {
    await sendApplicantReportEmail(applicantId, reportId);
  };

  const handleGenerateActionPlan = async () => {
    if (!applicantReport) return;

    try {
      setGeneratingActionPlan(true);
      const actionPlan = await generateApplicantActionPlan(applicantId, applicantReport.id!, language.code);
      const fileName = `${applicant?.contactInformation.firstName}_${applicant?.contactInformation.lastName}_action_plan`;
      downloadBase64File(actionPlan.pdfEncoded, fileName);

      dispatch(
        showSnackbar({
          message: t("applicantDetails.report.actionPlanCreated"),
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } finally {
      setGeneratingActionPlan(false);
    }
  };

  const manageCardChildren = (
    <Card sx={{ p: 5, mt: 5 }}>
      <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold", fontSize: "1.1rem" }}>
        {t("applicantDetails.report.actionPlan")}
      </Typography>

      <Typography variant="body1" sx={{ mb: 1 }}>
        {t("applicantDetails.report.selectLanguage")}
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <Select
          labelId="language-label"
          value={language.code}
          onChange={(e) => setLanguage(getLanguageByCode(e.target.value))}
          renderValue={(selected) => {
            const selectedLanguage = SUPPORTED_LANGUAGES.find((lang) => lang.code === selected);
            return selectedLanguage ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Flag code={selectedLanguage.flag} style={{ marginRight: 8 }} width={20} />
                {selectedLanguage.name}
              </Box>
            ) : (
              <em>{t("applicationDetails.page.changeApplicationOriginalLanguage")}</em>
            );
          }}
        >
          {SUPPORTED_LANGUAGES.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Flag code={language.flag} style={{ marginRight: 8 }} width={20} />
                {language.name}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        startIcon={isGeneratingActionPlan ? <CircularProgress size={20} /> : <Create />}
        onClick={handleGenerateActionPlan}
        fullWidth
        disabled={!applicantReport || isGeneratingActionPlan}
        sx={{ pl: 2 }}
      >
        {isGeneratingActionPlan
          ? t("applicantDetails.report.generating")
          : t("applicantDetails.report.createActionPlan")}
      </Button>
    </Card>
  );

  return (
    <ReportPreviewModal<ApplicantReport>
      isOpen={isOpen}
      handleClose={handleClose}
      reportMode={reportMode}
      fetchReport={fetchReport}
      sendReportEmail={sendReportEmail}
      reportName="applicantDetails"
      report={applicantReport}
      setReport={setApplicantReport}
      additionalActionContent={manageCardChildren}
    />
  );
};

export default ApplicantReportPreviewModal;
