import { ApplicantItem } from "../../types/ApplicantItem";
import axiosInstance from "../../utils/AxiosInstance";

async function getApplicantItems(jobCoachId: string): Promise<ApplicantItem[]> {
  try {
    const response = await axiosInstance.get("/applicants_list", {
      params: {
        job_coach_id: jobCoachId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export default getApplicantItems;
