import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  InputAdornment,
  IconButton,
  CircularProgress,
  Collapse,
} from "@mui/material";
import { Visibility, VisibilityOff, CheckCircleOutline } from "@mui/icons-material";
import { resetPassword, validateResetToken } from "./ResetPasswordRepository";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MaijaLoadingPage from "../../components/MaijaLoadingPage";

const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] = useState<boolean>(false);

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [isTokenValid, setIsTokenValid] = useState<boolean | null>(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      validateResetToken(token)
        .then(() => {
          setIsTokenValid(true);
        })
        .catch(() => {
          setIsTokenValid(false);
          dispatch(
            showSnackbar({
              message: t("resetPassword.invalidOrExpiredToken"),
              severity: "error",
            }),
          );
          navigate("/login");
        });
    }
  }, [token, navigate, dispatch, t]);

  if (isTokenValid === null || isLoading) {
    <MaijaLoadingPage />;
  }

  if (!isTokenValid) {
    return null;
  }

  const validatePasswords = () => {
    const newErrors: { [key: string]: string } = {};

    if (!password) {
      newErrors.password = t("resetPassword.errorPasswordRequired");
    } else if (password.length < 8) {
      newErrors.password = t("resetPassword.errorPasswordTooShort");
    }

    if (!confirmPassword) {
      newErrors.confirmPassword = t("resetPassword.errorConfirmPasswordRequired");
    } else if (password !== confirmPassword) {
      newErrors.password = t("resetPassword.errorPasswordsDoNotMatch");
      newErrors.confirmPassword = t("resetPassword.errorPasswordsDoNotMatch");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const handleSubmit = () => {
    if (!validatePasswords()) {
      return;
    }

    setIsLoading(true);

    resetPassword(token!, password)
      .then(() => {
        setSuccess(true);
        dispatch(
          showSnackbar({
            message: t("resetPassword.success"),
            severity: "success",
          }),
        );

        setPassword("");
        setConfirmPassword("");

        setTimeout(() => {
          setSuccess(false);
          navigate("/login");
        }, 2000);
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("resetPassword.error"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `linear-gradient(20deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
      }}
    >
      <Card sx={{ width: "100%", maxWidth: 500 }}>
        <CardContent>
          <Container component="main">
            <Collapse in={!success}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h1" variant="h5">
                  {t("resetPassword.title")}
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    sx={{ mt: 3 }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label={t("resetPassword.newPassword")}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    value={password}
                    onChange={handlePasswordChange}
                    error={!!errors.password}
                    helperText={errors.password}
                    data-cy="password"
                    onFocus={() => setIsPasswordFocused(true)}
                    onBlur={() => setIsPasswordFocused(false)}
                    InputProps={{
                      endAdornment: isPasswordFocused ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                  <TextField
                    sx={{ mt: 1 }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="confirm-password"
                    label={t("resetPassword.confirmPassword")}
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    data-cy="confirm-password"
                    onFocus={() => setIsConfirmPasswordFocused(true)}
                    onBlur={() => setIsConfirmPasswordFocused(false)}
                    InputProps={{
                      endAdornment: isConfirmPasswordFocused ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3 }}
                    onClick={handleSubmit}
                    data-cy="submit"
                  >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : t("resetPassword.submit")}
                  </Button>
                </Box>
              </Box>
            </Collapse>

            <Collapse in={success}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <CheckCircleOutline style={{ fontSize: 80, color: "green" }} />
                <Typography variant="h5" sx={{ mt: 2 }}>
                  {t("resetPassword.success")}
                </Typography>
              </Box>
            </Collapse>
          </Container>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ResetPasswordPage;
