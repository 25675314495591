import { TableCell, TableCellProps, TableHead, TableRow, useTheme } from "@mui/material";
import ClickableHeaderItem from "./ClickableHeaderItem";

export interface MaijaTableColumn<T> {
  key: string;
  label: string;
  onSort?: (a: T, b: T) => number;
  renderCell?: (item: T) => React.ReactNode;
  cellProps?: Partial<TableCellProps>;
}

type TableHeaderProps<T> = {
  columns: MaijaTableColumn<T>[];
  handleSort: (sortFunction: (a: T, b: T) => number) => void;
};

const MaijaTableHeader = <T,>({ columns, handleSort }: TableHeaderProps<T>) => {
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
        {columns.map((column) => (
          <TableCell key={column.key} sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
            <ClickableHeaderItem
              text={column.label}
              onClick={() => {
                if (column.onSort) {
                  handleSort(column.onSort);
                }
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type TableRowProps<T> = {
  item: T;
  columns: MaijaTableColumn<T>[];
  onClick?: () => void;
};

const MaijaTableRow = <T,>({ item, columns, onClick }: TableRowProps<T>) => {
  return (
    <TableRow hover onClick={onClick} style={{ cursor: "pointer" }}>
      {columns.map((column) => (
        <TableCell key={column.key} sx={column.cellProps} data-cy="table-row">
          {column.renderCell ? column.renderCell(item) : (item[column.key as keyof T] as React.ReactNode)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export { MaijaTableHeader, MaijaTableRow };
