import { OfficeDetails } from "../../types/OfficeDetails";
import axiosInstance from "../../utils/AxiosInstance";

export async function getOfficeDetails(officeId: string): Promise<OfficeDetails | undefined> {
  try {
    const response = await axiosInstance.get("/office/details", {
      params: {
        office_id: officeId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function removeOffice(officeId: string): Promise<boolean> {
  try {
    await axiosInstance.post("/office/delete", {
      office_id: officeId,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
