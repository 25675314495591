import React, { useState } from "react";

import { getJobCoachReport, getNewJobCoachReport, sendJobCoachReportEmail } from "./JobCoachReportsRepository";
import { JobCoachReport } from "../../types/JobCoachReport";
import { useAppDispatch } from "../../hooks";
import ReportPreviewModal, { PreviewModalMode } from "../../components/ReportPreviewModal";
import { jobCoachDetailsPageSlice } from "../../reducers/jobCoachDetailsPageSlice";

interface JobCoachReportPreviewModalProps {
  isOpen: boolean;
  jobCoachId: string;
  jobCoachName: string;
  reportMode: PreviewModalMode;
  handleClose: () => void;
}

const JobCoachReportPreviewModal: React.FC<JobCoachReportPreviewModalProps> = ({
  isOpen,
  jobCoachId,
  jobCoachName,
  reportMode,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const [jobCoachReport, setJobCoachReport] = useState<JobCoachReport | undefined>(undefined);

  const fetchReport = async (reportId?: string): Promise<JobCoachReport> => {
    if (reportId) {
      const newReport = await getJobCoachReport(jobCoachId, reportId);
      return newReport;
    } else {
      const newReport = await getNewJobCoachReport(jobCoachId);
      dispatch(
        jobCoachDetailsPageSlice.actions.addJobCoachDetailsReportsList({
          jobCoachId: jobCoachId,
          report: { ...newReport },
        }),
      );
      return newReport;
    }
  };

  const sendReportEmail = async (reportId: string): Promise<void> => {
    await sendJobCoachReportEmail(jobCoachId, reportId);
  };

  return (
    <ReportPreviewModal<JobCoachReport>
      isOpen={isOpen}
      handleClose={handleClose}
      reportMode={reportMode}
      fetchReport={fetchReport}
      sendReportEmail={sendReportEmail}
      reportName={jobCoachName}
      report={jobCoachReport}
      setReport={setJobCoachReport}
      additionalActionContent={null}
    />
  );
};

export default JobCoachReportPreviewModal;
