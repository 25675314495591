// CompanyCreatePage.tsx
import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  AppBar,
  Backdrop,
  Tabs,
  Tab,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Box,
  Autocomplete,
} from "@mui/material";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import DomainIcon from "@mui/icons-material/Domain";
import successAnimationData from "../../assets/lottie/success.json";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { setOffices } from "../../reducers/officeListPageSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createOffice, createJobCoach } from "./CompanyCreateRepository";
import MaijaContainer from "../../components/MaijaContainer";
import MaijaLottieAnimation from "../../components/MaijaLottieAnimation";
import { validateEmail, validatePhoneNumber, validatePlainText } from "../../utils/TextValidation";
import { fetchOfficeItems } from "../../pages/office-list/OfficeListRepository";
import {
  clearCompanyCreatePageData,
  setCompanyCreateSelectedOffice,
  setCompanyCreateSelectedTab,
} from "../../reducers/companyCreatePageSlice";
import { useTranslation } from "react-i18next";
import CreateApplicantForm from "../create-applicant/CreateApplicantForm";
import { useNavigate } from "react-router-dom";
import { navigateToJobCoachDetails, navigateToOfficeDetails } from "../../Routes";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { SupervisedUserCircle } from "@mui/icons-material";

export enum CompanyCreatePageTab {
  OfficeTab,
  JobCoachTab,
  ApplicantTab,
}

const CompanyCreatePage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [successAnimation, setSuccessAnimation] = useState<boolean>(false);
  const tab = useAppSelector((state) => state.companyCreatePageSlice.selectedTab);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const setCompanyCreateTab = (newValue: CompanyCreatePageTab) => {
    dispatch(setCompanyCreateSelectedTab(newValue));
  };

  useEffect(() => {
    dispatch(clearCompanyCreatePageData());
  }, [tab, dispatch]);

  return (
    <MaijaContainer sx={{ padding: 5 }}>
      <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
        <CircularProgress size={100} />
      </Backdrop>

      <MaijaLottieAnimation animationData={successAnimationData} size={300} show={successAnimation} />

      <Box display="flex" flexDirection="column" alignItems="center" sx={{ minHeight: "100%" }}>
        <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 3 }}>
          <Tabs
            value={tab}
            onChange={(event, newValue) => setCompanyCreateTab(newValue)}
            indicatorColor="primary"
            textColor="inherit"
            centered
          >
            <Tab label={t("companyCreate.createOffice")} sx={{ minWidth: isMobile ? 0 : 200 }} />
            <Tab label={t("companyCreate.createJobCoach")} sx={{ minWidth: isMobile ? 0 : 200 }} />
            <Tab label={t("companyCreate.createApplicant")} sx={{ minWidth: isMobile ? 0 : 200 }} />
          </Tabs>
        </AppBar>

        {tab === CompanyCreatePageTab.OfficeTab && (
          <CreateOffice isLoading={loading} setLoading={setLoading} setSuccessAnimation={setSuccessAnimation} />
        )}

        {tab === CompanyCreatePageTab.JobCoachTab && (
          <CreateJobCoach isLoading={loading} setLoading={setLoading} setSuccessAnimation={setSuccessAnimation} />
        )}
        {tab === CompanyCreatePageTab.ApplicantTab && (
          <CreateApplicantForm isLoading={loading} setLoading={setLoading} setSuccessAnimation={setSuccessAnimation} />
        )}
      </Box>
    </MaijaContainer>
  );
};

export interface CompanyCreateInputProps {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setSuccessAnimation: Dispatch<SetStateAction<boolean>>;
}

export const CreateOffice: React.FC<CompanyCreateInputProps> = ({ isLoading, setLoading, setSuccessAnimation }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [officeName, setOfficeName] = useState("");
  const [officeNameValid, setOfficeNameValid] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onCreateOfficeClick = () => {
    setOfficeNameValid(validatePlainText(officeName));
    // Has to be calcualted again since state does not update in time...
    if (!validatePlainText(officeName)) {
      return;
    }

    setLoading(true);
    createOffice(officeName)
      .then((response) => {
        if (response) {
          setSuccessAnimation(true);
          setTimeout(() => {
            setSuccessAnimation(false);
            navigateToOfficeDetails(navigate, response.id);
          }, 2000);
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("companyCreate.officeCreateError"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        setOfficeName("");
      });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ minHeight: "100%", p: isMobile ? 2 : 5 }}>
      <DomainAddIcon style={{ fontSize: 200 }} />
      <Typography variant="h3" gutterBottom sx={{ mt: 2 }}>
        {t("companyCreate.addNewOffice")}
      </Typography>
      <Box
        display="flex"
        alignContent="center"
        alignItems="flex-start"
        flexDirection="column"
        sx={{ width: "100%", maxWidth: isMobile ? "100%" : "400px", p: isMobile ? 2 : 0 }}
      >
        <TextField
          label={t("companyCreate.officeName")}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
          value={officeName}
          error={!officeNameValid}
          helperText={!officeNameValid ? t("companyCreate.enterOfficeName") : ""}
          onChange={(e) => {
            if (!officeNameValid) {
              setOfficeNameValid(validatePlainText(e.target.value));
            }
            setOfficeName(e.target.value);
          }}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 6, fontSize: "1.1rem" }}
        onClick={onCreateOfficeClick}
        disabled={isLoading}
      >
        {t("companyCreate.createOfficeButton")}
      </Button>
    </Box>
  );
};

export const CreateJobCoach: React.FC<CompanyCreateInputProps> = ({ isLoading, setLoading, setSuccessAnimation }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    fetchOfficeItems().then((data) => dispatch(setOffices(data)));
  }, [dispatch]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const offices = useAppSelector((state) => state.officeListPageSlice.offices);
  const selectedOffice = useAppSelector((state) => state.companyCreatePageSlice.selectedOffice);
  const [selectedOfficeValid, setSelectedOfficeValid] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastName, setLastName] = useState("");
  const [lastNameValid, setLastNameValid] = useState(true);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);

  const onCreateOfficeClick = () => {
    setSelectedOfficeValid(selectedOffice != null);
    setFirstNameValid(validatePlainText(firstName));
    setLastNameValid(validatePlainText(lastName));
    setEmailValid(validateEmail(email));
    setPhoneNumberValid(validatePhoneNumber(phoneNumber));

    if (
      !selectedOffice ||
      !validatePlainText(firstName) ||
      !validatePlainText(lastName) ||
      !validateEmail(email) ||
      !validatePhoneNumber(phoneNumber)
    ) {
      return;
    }

    setLoading(true);
    createJobCoach(firstName, lastName, email, phoneNumber, selectedOffice.id)
      .then((response) => {
        if (response) {
          setSuccessAnimation(true);
          setTimeout(() => {
            setSuccessAnimation(false);
            navigateToJobCoachDetails(navigate, response.id);
          }, 2000);
        }
      })
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("companyCreate.jobCoachCreateError"),
            severity: "error",
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
      });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" sx={{ minHeight: "100%", p: isMobile ? 2 : 5 }}>
      <SupervisedUserCircle style={{ fontSize: 200 }} />
      <Typography variant="h3" gutterBottom sx={{ mt: 2 }}>
        {t("companyCreate.addNewJobCoach")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("companyCreate.jobCoachInstructions")}
      </Typography>
      <Box
        display="flex"
        alignContent="center"
        alignItems="flex-start"
        flexDirection="column"
        sx={{ width: "100%", maxWidth: isMobile ? "100%" : "400px", p: isMobile ? 2 : 0 }}
      >
        <Autocomplete
          sx={{ mt: 4 }}
          fullWidth
          options={offices}
          getOptionLabel={(option) => `${option.name}`}
          value={selectedOffice}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("companyCreate.selectOffice")}
              variant="outlined"
              helperText={!selectedOfficeValid ? t("companyCreate.enterOffice") : ""}
              error={!selectedOfficeValid}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <DomainIcon />
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          onChange={(_, newValue) => {
            if (!selectedOfficeValid) {
              setSelectedOfficeValid(newValue != null);
            }
            dispatch(setCompanyCreateSelectedOffice(newValue));
          }}
        />
        <TextField
          label={t("companyCreate.firstName")}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
          value={firstName}
          error={!firstNameValid}
          helperText={!firstNameValid ? t("companyCreate.enterFirstName") : ""}
          onChange={(e) => {
            if (!firstNameValid) {
              setFirstNameValid(validatePlainText(e.target.value));
            }
            setFirstName(e.target.value);
          }}
        />
        <TextField
          label={t("companyCreate.lastName")}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
          value={lastName}
          error={!lastNameValid}
          helperText={!lastNameValid ? t("companyCreate.enterLastName") : ""}
          onChange={(e) => {
            if (!lastNameValid) {
              setLastNameValid(validatePlainText(e.target.value));
            }
            setLastName(e.target.value);
          }}
        />
        <TextField
          label={t("companyCreate.email")}
          variant="outlined"
          margin="normal"
          fullWidth
          type="email"
          sx={{ mt: 4 }}
          value={email}
          error={!emailValid}
          helperText={!emailValid ? t("companyCreate.invalidEmail") : ""}
          onChange={(e) => {
            if (!emailValid) {
              setEmailValid(validateEmail(e.target.value));
            }
            setEmail(e.target.value);
          }}
        />
        <TextField
          label={t("companyCreate.phoneNumber")}
          variant="outlined"
          margin="normal"
          fullWidth
          sx={{ mt: 4 }}
          value={phoneNumber}
          error={!phoneNumberValid}
          helperText={!phoneNumberValid ? t("companyCreate.invalidPhone") : ""}
          onChange={(e) => {
            if (!phoneNumberValid) {
              setPhoneNumberValid(validatePhoneNumber(e.target.value));
            }
            setPhoneNumber(e.target.value);
          }}
        />
      </Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 6, fontSize: "1.1rem" }}
        onClick={onCreateOfficeClick}
        disabled={isLoading}
      >
        {t("companyCreate.createJobCoachButton")}
        {isLoading && <CircularProgress size={22} sx={{ ml: 3 }} />}
      </Button>
    </Box>
  );
};

export default CompanyCreatePage;
