import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { CompanyCreatePageTab } from "../pages/company-create/CompanyCreatePage";
import { OfficeItem } from "../types/OfficeItem";
import { JobCoachItem } from "../types/JobCoachItem";

interface CompanyCreatePageState {
  selectedTab: CompanyCreatePageTab;
  selectedOffice: OfficeItem | null;
  selectedJobCoach: JobCoachItem | null;
}

const initialState: CompanyCreatePageState = {
  selectedTab: CompanyCreatePageTab.OfficeTab,
  selectedOffice: null,
  selectedJobCoach: null,
};

export const companyCreateSlice = createSlice({
  name: "companyCreate",
  initialState,
  reducers: {
    setCompanyCreateSelectedTab: (state, action: PayloadAction<CompanyCreatePageTab>) => {
      state.selectedTab = action.payload;
    },
    setCompanyCreateSelectedOffice: (state, action: PayloadAction<OfficeItem | null>) => {
      state.selectedOffice = action.payload;
    },
    setCompanyCreateSelectedJobCoach: (state, action: PayloadAction<JobCoachItem | null>) => {
      state.selectedJobCoach = action.payload;
    },
    clearCompanyCreatePage: (state) => {
      state.selectedTab = CompanyCreatePageTab.OfficeTab;
      state.selectedOffice = null;
      state.selectedJobCoach = null;
    },
    clearCompanyCreatePageData: (state) => {
      state.selectedOffice = null;
      state.selectedJobCoach = null;
    },
  },
});

export const {
  setCompanyCreateSelectedTab,
  setCompanyCreateSelectedOffice,
  setCompanyCreateSelectedJobCoach,
  clearCompanyCreatePage,
  clearCompanyCreatePageData,
} = companyCreateSlice.actions;
export const companyCreatePage = (state: RootState) => state.companyCreatePageSlice;
export default companyCreateSlice.reducer;
