import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { JobCoachDetails } from "../types/JobCoachDetails";
import { JobCoachReportListItem } from "../types/JobCoachReport";

interface JobCoachDetailsPageState {
  jobCoachesMap: { [id: string]: JobCoachDetails };
  reportListItemsMap: { [id: string]: JobCoachReportListItem[] };
}

const initialState: JobCoachDetailsPageState = {
  jobCoachesMap: {},
  reportListItemsMap: {},
};

export const jobCoachDetailsPageSlice = createSlice({
  name: "jobCoachDetails",
  initialState,
  reducers: {
    pushJobCoach: (state, action: PayloadAction<JobCoachDetails>) => {
      state.jobCoachesMap[action.payload.jobCoachId] = action.payload;
    },
    setJobCoachDetailsReportsList: (
      state,
      action: PayloadAction<{
        jobCoachId: string;
        reports: JobCoachReportListItem[];
      }>,
    ) => {
      state.reportListItemsMap[action.payload.jobCoachId] = action.payload.reports;
    },
    addJobCoachDetailsReportsList: (
      state,
      action: PayloadAction<{
        jobCoachId: string;
        report: JobCoachReportListItem;
      }>,
    ) => {
      const { jobCoachId, report } = action.payload;

      state.reportListItemsMap[jobCoachId] = [...(state.reportListItemsMap[jobCoachId] || []), report].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    },
    clearJobCoachDetailsPageSlice: (state) => {
      state.jobCoachesMap = {};
      state.reportListItemsMap = {};
    },
  },
});

export const {
  pushJobCoach,
  setJobCoachDetailsReportsList,
  addJobCoachDetailsReportsList,
  clearJobCoachDetailsPageSlice,
} = jobCoachDetailsPageSlice.actions;
export const jobCoachDetailsPage = (state: RootState) => state.applicantDetailsPageSlice;
export default jobCoachDetailsPageSlice.reducer;
