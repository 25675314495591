import React, { useState, useMemo, useCallback } from "react";
import { Paper, TextField, Table, TableBody, TableContainer, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { JobCoachItem } from "../types/JobCoachItem";
import { useTranslation } from "react-i18next";
import { navigateToJobCoachDetails } from "../Routes";
import MaijaEmptyState from "./MaijaEmptyState";
import SearchIcon from "@mui/icons-material/Search";
import { MaijaTableHeader, MaijaTableRow, MaijaTableColumn } from "./MaijaTable";

type JobCoachListProps = {
  jobCoaches: JobCoachItem[];
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
};

const JobCoachList: React.FC<JobCoachListProps> = ({ jobCoaches, searchQuery, setSearchQuery }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [sortFunction, setSortFunction] = useState<((a: JobCoachItem, b: JobCoachItem) => number) | null>(null); // eslint-disable-line

  const sortedJobCoaches = useMemo(() => {
    if (sortFunction) {
      return [...jobCoaches].sort(sortFunction);
    }
    return jobCoaches;
  }, [jobCoaches, sortFunction]);

  const filteredJobCoaches = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return sortedJobCoaches.filter((jobCoach) => jobCoach.fullName.toLowerCase().includes(query));
  }, [sortedJobCoaches, searchQuery]);

  const columns: MaijaTableColumn<JobCoachItem>[] = useMemo(
    () => [
      {
        key: "fullName",
        label: t("jobCoachesList.name"),
        onSort: (a, b) => a.fullName.localeCompare(b.fullName),
        renderCell: (item) => item.fullName,
      },
      {
        key: "numberApplicants",
        label: t("jobCoachesList.numberOfApplicants"),
        onSort: (a, b) => a.numberApplicants - b.numberApplicants,
        renderCell: (item) => item.numberApplicants,
      },
      {
        key: "numberJobsLast30Days",
        label: t("jobCoachesList.appliedJobsLast30Days"),
        onSort: (a, b) => a.numberJobsLast30Days - b.numberJobsLast30Days,
        renderCell: (item) => item.numberJobsLast30Days,
      },
    ],
    [t],
  );

  const handleSort = useCallback(
    (sortFunc: (a: JobCoachItem, b: JobCoachItem) => number) => {
      if (sortFunction === sortFunc) {
        const reversedSort = (a: JobCoachItem, b: JobCoachItem) => -sortFunc(a, b);
        setSortFunction(() => reversedSort);
      } else {
        setSortFunction(() => sortFunc);
      }
    },
    [sortFunction],
  );

  return (
    <Paper sx={{ py: 4, px: 5 }}>
      <TextField
        label={t("jobCoachesList.search")}
        variant="outlined"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 3 }}
      />

      {filteredJobCoaches.length > 0 ? (
        <TableContainer sx={{ mb: 30, borderRadius: 2, overflow: "hidden" }}>
          <Table sx={{ borderCollapse: "separate", borderSpacing: 0 }}>
            <MaijaTableHeader columns={columns} handleSort={handleSort} />
            <TableBody>
              {filteredJobCoaches.map((jobCoach) => (
                <MaijaTableRow
                  key={jobCoach.id}
                  item={jobCoach}
                  columns={columns}
                  onClick={() => navigateToJobCoachDetails(navigate, jobCoach.id)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <MaijaEmptyState message={t("jobCoachesList.noJobCoaches")} />
      )}
    </Paper>
  );
};

export default JobCoachList;
