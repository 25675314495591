import React, { useState, useEffect } from "react";
import { Box, Card, List, ListItemButton, Typography, Button, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InboxIcon from "@mui/icons-material/Inbox";
import { SendOut } from "../../../types/SendOut";
import { deleteSendOut, fetchSendOuts } from "./SendOutsRepository";
import SendOutDetails from "./SendOutDetails";
import CreateEditSendOutModal from "./CreateEditSendOutModal";
import MaijaContainer from "../../../components/MaijaContainer";
import { useAppDispatch } from "../../../hooks";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";

const CreateSendOutsPage = () => {
  const [sendOuts, setSendOuts] = useState<SendOut[]>([]);
  const [selectedSendOut, setSelectedSendOut] = useState<SendOut | null>(null);
  const [detailsReloadTrigger, setDetailsReloadTrigger] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    fetchSendOuts()
      .then(setSendOuts)
      .catch(() => {
        dispatch(
          showSnackbar({
            message: t("createSendoutPage.fetchError"),
            severity: "error",
          }),
        );
      });
  }, [dispatch, t]);

  const handleCreateClick = () => {
    setIsEditing(false);
    setSelectedSendOut(null);
    setModalOpen(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteSendOut(id);
      setSendOuts(sendOuts.filter((so) => so.id !== id));
      setSelectedSendOut(null);
      dispatch(
        showSnackbar({
          message: t("createSendoutPage.deleteSuccess"),
          severity: "success",
        }),
      );
    } catch (error) {
      dispatch(
        showSnackbar({
          message: t("createSendoutPage.deleteError"),
          severity: "error",
        }),
      );
    }
  };

  return (
    <MaijaContainer>
      <Box display="flex" justifyContent="space-between" sx={{ pt: 5 }}>
        <Box width="48%">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h5">{t("createSendoutPage.templatesTitle")}</Typography>
          </Box>
          {sendOuts.length > 0 ? (
            <List component={Card}>
              {sendOuts.map((sendOut) => (
                <ListItemButton
                  key={sendOut.id}
                  onClick={() => setSelectedSendOut(sendOut)}
                  selected={selectedSendOut?.id === sendOut.id}
                >
                  <Typography>{sendOut.title}</Typography>
                </ListItemButton>
              ))}
            </List>
          ) : (
            <Card sx={{ p: 5, textAlign: "center" }}>
              <InboxIcon sx={{ fontSize: 40, mb: 2, color: "text.secondary" }} />
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t("createSendoutPage.noTemplates")}
              </Typography>
              <Button variant="contained" color="primary" onClick={handleCreateClick} sx={{ mt: 2 }}>
                {t("createSendoutPage.createNewTemplate")}
              </Button>
            </Card>
          )}
        </Box>
        <Box width="48%" position="sticky" sx={{ mt: 10 }}>
          {selectedSendOut ? (
            <SendOutDetails
              sendOut={selectedSendOut}
              reloadTrigger={detailsReloadTrigger}
              onEdit={() => {
                setIsEditing(true);
                setModalOpen(true);
              }}
              onDelete={() => handleDelete(selectedSendOut.id)}
            />
          ) : (
            <Card sx={{ p: 5, textAlign: "center" }}>
              <Typography variant="h6">{t("createSendoutPage.selectTemplate")}</Typography>
            </Card>
          )}
        </Box>
        <CreateEditSendOutModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          isEditing={isEditing}
          sendOut={isEditing ? selectedSendOut : null}
          onSave={(newSendOut: SendOut) => {
            setModalOpen(false);
            if (isEditing) {
              setSendOuts(sendOuts.map((so) => (so.id === newSendOut.id ? newSendOut : so)));
              setSelectedSendOut(newSendOut);
              setDetailsReloadTrigger(!detailsReloadTrigger);

              dispatch(
                showSnackbar({
                  message: t("createSendoutPage.editSuccess"),
                  severity: "success",
                }),
              );
            } else {
              setSendOuts([...sendOuts, newSendOut]);
              dispatch(
                showSnackbar({
                  message: t("createSendoutPage.createSuccess"),
                  severity: "success",
                }),
              );
            }
          }}
        />
      </Box>

      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
        }}
        onClick={handleCreateClick}
      >
        <AddIcon sx={{ mr: 1 }} />
        <Typography variant="body2">{t("createSendoutPage.addSendout")}</Typography>
      </Fab>
    </MaijaContainer>
  );
};

export default CreateSendOutsPage;
