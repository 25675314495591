export const downloadBase64File = (encodedFile: string, name: string) => {
  const formattedName = name.replace(/ /g, "_");
  const linkSource = `data:application/pdf;base64,${encodedFile}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = `${formattedName}.pdf`;
  downloadLink.click();
};

export const readFileAsDataURL = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
