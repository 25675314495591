import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApplicantName } from "../types/ApplicantName";
import { Meeting } from "../types/Meeting";
import { SendOutTime } from "../types/SendOutTime";
import { SendOut } from "../types/SendOut";
import { PlanningPageTab } from "../pages/planning/PlanningPage";

interface PlanningPageState {
  meetings: Meeting[];
  sendoutTimes: SendOutTime[];
  applicantNames: ApplicantName[];
  sendOuts: SendOut[];
  selectedMeeting: Meeting | undefined;
  selectedSendOutTime: SendOutTime | undefined;
  selectedTab: PlanningPageTab;
}

const initialState: PlanningPageState = {
  meetings: [],
  sendoutTimes: [],
  applicantNames: [],
  sendOuts: [],
  selectedMeeting: undefined,
  selectedSendOutTime: undefined,
  selectedTab: PlanningPageTab.MeetingsTab,
};

export const planningPageSlice = createSlice({
  name: "planning",
  initialState,
  reducers: {
    setApplicantNames: (state, action: PayloadAction<ApplicantName[]>) => {
      state.applicantNames = action.payload;
    },
    setMeetings: (state, action: PayloadAction<Meeting[]>) => {
      state.meetings = action.payload;
    },
    removeMeeting: (state, action: PayloadAction<string>) => {
      state.meetings = state.meetings.filter((m) => m.id !== action.payload);
    },
    setSendOutTimes: (state, action: PayloadAction<SendOutTime[]>) => {
      state.sendoutTimes = action.payload;
    },
    setSendouts: (state, action: PayloadAction<SendOut[]>) => {
      state.sendOuts = action.payload;
    },
    setSelectedMeeting: (state, action: PayloadAction<Meeting | undefined>) => {
      state.selectedMeeting = action.payload;
    },
    setSelectedSendOutTime: (state, action: PayloadAction<SendOutTime | undefined>) => {
      state.selectedSendOutTime = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<PlanningPageTab>) => {
      state.selectedTab = action.payload;
    },
    clearPlanningPage: (state) => {
      state.meetings = [];
      state.sendoutTimes = [];
      state.applicantNames = [];
      state.sendOuts = [];
      state.selectedMeeting = undefined;
      state.selectedSendOutTime = undefined;
      state.selectedTab = PlanningPageTab.MeetingsTab;
    },
  },
});

export const {
  setApplicantNames,
  setMeetings,
  setSendOutTimes,
  setSendouts,
  removeMeeting,
  setSelectedMeeting,
  setSelectedSendOutTime,
  setSelectedTab,
  clearPlanningPage,
} = planningPageSlice.actions;
export const planningPage = (state: RootState) => state.planningPageSlice;
export default planningPageSlice.reducer;
