import React from "react";
import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

interface MaijaLoadingPageProps {
  isFullscreen?: boolean;
}

const MaijaErrorPage: React.FC<MaijaLoadingPageProps> = ({ isFullscreen }) => {
  const { t } = useTranslation();

  if (isFullscreen)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ m: 5 }}
        width={"100%"}
        height={"100%"}
      >
        <ErrorOutlineIcon style={{ fontSize: 150 }} color="error" sx={{ mb: 12 }} />
        <Typography variant="h4" gutterBottom>
          {t("generic.error")}
        </Typography>
        <Typography variant="body1">{t("generic.error_request")}</Typography>
      </Box>
    );

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ m: 5 }}>
      <ErrorOutlineIcon style={{ fontSize: 100 }} color="error" />
      <Typography variant="h4" gutterBottom>
        {t("generic.error")}
      </Typography>
      <Typography variant="body1">{t("generic.error_request")}</Typography>
    </Box>
  );
};

export default MaijaErrorPage;
