import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import JobCoachDetailsModal from "./JobCoachDetailsModal";
import { UpdateJobCoachDetails } from "./JobCoachDetailsRepository";

interface Office {
  id: string;
  name: string;
}

export enum JobCoachDetailsFieldType {
  OfficeId = "officeId",
  Email = "email",
  PhoneNumber = "phoneNumber",
}

export type JobCoachDetailsFieldValueMap = {
  [JobCoachDetailsFieldType.OfficeId]: string;
  [JobCoachDetailsFieldType.Email]: string;
  [JobCoachDetailsFieldType.PhoneNumber]: string;
};

interface JobCoachDetailsFieldProps<T extends JobCoachDetailsFieldType> {
  type: T;
  info: JobCoachDetailsFieldValueMap[T];
  leadingSlot?: JSX.Element;
  getUpdatedJobCoachDetails: (value: JobCoachDetailsFieldValueMap[T]) => UpdateJobCoachDetails | undefined;
  jobCoachId: string;
  offices: Office[];
}

const JobCoachDetailsField = <T extends JobCoachDetailsFieldType>({
  type,
  info,
  leadingSlot,
  getUpdatedJobCoachDetails,
  jobCoachId,
  offices,
}: JobCoachDetailsFieldProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsModalOpen(true);
  };

  const handleDialogClose = () => {
    setIsModalOpen(false);
  };

  const displayInfo = () => {
    return info;
  };

  const renderField = () => {
    switch (type) {
      case JobCoachDetailsFieldType.OfficeId:
      case JobCoachDetailsFieldType.Email:
      case JobCoachDetailsFieldType.PhoneNumber:
        return renderTextInput(info, type, leadingSlot, displayInfo, handleDialogOpen);
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      {renderField()}
      <JobCoachDetailsModal
        type={type}
        open={isModalOpen}
        onClose={handleDialogClose}
        getUpdatedJobCoachDetails={getUpdatedJobCoachDetails}
        initialValue={info}
        jobCoachId={jobCoachId}
        offices={offices}
      />
    </Box>
  );
};

const renderTextInput = (
  info: string,
  type: JobCoachDetailsFieldType,
  leadingSlot: JSX.Element | undefined,
  displayInfo: () => string,
  handleDialogOpen: () => void,
) => {
  return (
    <Box sx={{ textAlign: "left" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {leadingSlot ? leadingSlot : <></>}
        <Typography variant="subtitle1" sx={{}}>
          <Box sx={{ display: "block" }}>{displayInfo()}</Box>
        </Typography>
        <IconButton sx={{ mx: 1 }} onClick={handleDialogOpen}>
          <Edit />
        </IconButton>
      </Box>
    </Box>
  );
};

export default JobCoachDetailsField;
