import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ApplicantReportListItem } from "../../types/ApplicantReport";
import { getApplicantReports } from "./ApplicantReportsRepository";
import { applicantDetailsPageSlice } from "../../reducers/applicantDetailsPageSlice";
import { ReportListComponent } from "../../components/ReportsListComponent";

interface ApplicantReportsComponentProps {
  applicantId: string;
  onShowReport: (reportId: string) => void;
}

export const ApplicantReportsComponent: React.FC<ApplicantReportsComponentProps> = ({ applicantId, onShowReport }) => {
  const dispatch = useAppDispatch();

  const reports = useAppSelector((state) => state.applicantDetailsPageSlice.reportListItemsMap[applicantId] || []);

  useEffect(() => {
    getApplicantReports(applicantId).then((data) => {
      const sortedData = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      dispatch(
        applicantDetailsPageSlice.actions.setApplicantDetailsReportsList({
          applicantId,
          reports: sortedData,
        }),
      );
    });
  }, [applicantId, dispatch]);

  return (
    <ReportListComponent<ApplicantReportListItem>
      reports={reports}
      onShowReport={onShowReport}
      reportName="applicantDetails"
    />
  );
};
