import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { performLogout } from "./LogoutUtil";
import { useAppDispatch } from "../hooks";
import { clearLoggedInUser } from "../reducers/userSlice";
import { clearPlanningPage } from "../reducers/planningPageSlice";
import { clearApplicantDetailsPage } from "../reducers/applicantDetailsPageSlice";
import { clearApplicantListPage } from "../reducers/applicantListPageSlice";
import { clearJobCoachListPage } from "../reducers/jobCoachListPageSlice";
import { clearJobCoachDetailsPageSlice } from "../reducers/jobCoachDetailsPageSlice";
import { clearCompanyCreatePage } from "../reducers/companyCreatePageSlice";
import { clearTheme } from "../reducers/themeSlice";
import { clearAnalyticsPage } from "../reducers/analyticsPageSlice";

export function useLogout() {
  const { setUser, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = async () => {
    await performLogout();

    setUser(null);
    setIsAuthenticated(false);

    localStorage.removeItem("currentUser");

    dispatch(clearLoggedInUser());
    dispatch(clearAnalyticsPage());
    dispatch(clearApplicantDetailsPage());
    dispatch(clearApplicantListPage());
    dispatch(clearPlanningPage());
    dispatch(clearJobCoachListPage());
    dispatch(clearJobCoachDetailsPageSlice());
    dispatch(clearCompanyCreatePage());
    dispatch(clearTheme());

    navigate("/login", { replace: true });
  };

  return logout;
}
