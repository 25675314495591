import { EvaluationCriteria } from "../../types/EvaluationCriteria";
import axiosInstance from "../../utils/AxiosInstance";

async function createApplicant(
  firstName: string,
  lastName: string,
  email: string,
  evaluationCriteria: EvaluationCriteria,
  lastDay: string,
  jobCoachId: string | null,
  defaultJobCoachId: string | null,
): Promise<CreateApplicantResponse | undefined> {
  try {
    const response = await axiosInstance.post("/applicant/create", {
      job_coach_id: jobCoachId ?? defaultJobCoachId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      evaluation_criteria: evaluationCriteria,
      last_day: lastDay,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export type CreateApplicantResponse = {
  id: string;
  name: string;
  email: string;
};

export default createApplicant;
