import { JobCoachItem } from "./JobCoachItem";
import { OfficeItem } from "./OfficeItem";

export type OfficeDetails = {
  officeId: string;
  name: string;
  numberJobCoaches: number;
  numberApplicants: number;
  numberGotJob: number;
  numberAppliedJob: number;
  numberAppliedJobsLast30Days: number;
  jobCoachItems: JobCoachItem[];
};

export const officeDetailsToOfficeItem = (officeDetails: OfficeDetails): OfficeItem => {
  return {
    id: officeDetails.officeId,
    name: officeDetails.name,
    numberJobCoaches: officeDetails.numberJobCoaches,
    numberApplicants: officeDetails.numberApplicants,
    numberJobsLast30Days: officeDetails.numberAppliedJobsLast30Days,
  };
};
