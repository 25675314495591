import React, { useEffect, useState } from "react";
import { Card, Button, Typography, Box, Fab } from "@mui/material";
import { SendOut } from "../../../types/SendOut";
import { fetchSendOutFile } from "./SendOutsRepository";
import { useAppDispatch } from "../../../hooks";
import { showSnackbar } from "../../../reducers/snackbarSlice";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadBase64File } from "../../../utils/FileUtil";
import { MaijaA4Paper } from "../../../components/MaijaA4Paper";
import { maijaBackgroundSecondary } from "../../../components/MaijaColors";

interface SendOutDetailsProps {
  sendOut: SendOut;
  reloadTrigger: boolean; // Ugly solution to reload image when edited
  onEdit: () => void;
  onDelete: () => void;
}

const SendOutDetails: React.FC<SendOutDetailsProps> = ({ sendOut, reloadTrigger, onEdit, onDelete }) => {
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [pdfEncoded, setPdfEncoded] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (sendOut.hasFile) {
      fetchSendOutFile(sendOut.id)
        .then((data) => {
          setThumbnails(data.thumbnailsEncoded);
          setPdfEncoded(data.pdfEncoded);
        })
        .catch(() => {
          dispatch(
            showSnackbar({
              message: t("createSendoutPage.fileFetchError"),
              severity: "error",
            }),
          );
        });
    }
  }, [sendOut.hasFile, dispatch, t, sendOut.id, reloadTrigger]);

  const handleDownload = () => {
    if (!pdfEncoded) {
      dispatch(
        showSnackbar({
          message: t("generic.error"),
          severity: "error",
        }),
      );
    } else {
      const fileName = `${sendOut.title}_sendout`;
      downloadBase64File(pdfEncoded, fileName);
    }
  };

  return (
    <Card>
      <Box sx={{ p: 5 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h5">{sendOut.title}</Typography>
        </Box>
        <Box sx={{ my: 5 }}>
          <Button variant="contained" color="secondary" onClick={onEdit} sx={{ mr: 2 }}>
            {t("createSendoutPage.edit")}
          </Button>
          <Button variant="contained" color="error" onClick={onDelete}>
            {t("createSendoutPage.delete")}
          </Button>
        </Box>
        {sendOut.description && (
          <>
            <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2 }}>
              {t("createSendoutPage.descriptionLabel")}
            </Typography>
            <Typography variant="body1">{sendOut.description}</Typography>
          </>
        )}
      </Box>

      {sendOut.hasFile && (
        <>
          <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2, ml: 5, mb: 1 }}>
            {t("createSendoutPage.fileLabel")}
          </Typography>
          <Box
            sx={{
              p: 0,
              backgroundColor: maijaBackgroundSecondary,
            }}
          >
            <Box
              sx={{
                p: 2,
                backgroundColor: maijaBackgroundSecondary,
              }}
            >
              {thumbnails.map((thumbnail, index) => (
                <Box key={index} sx={{ mb: 2, width: "100%", position: "relative" }}>
                  <MaijaA4Paper loading={false}>
                    <img
                      src={`data:image/jpeg;base64,${thumbnail}`}
                      alt={`SendOut Page ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "auto",
                        borderRadius: "8px",
                      }}
                    />
                  </MaijaA4Paper>
                  {index === 0 && (
                    <Fab
                      color="default"
                      size="small"
                      onClick={handleDownload}
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgba(211, 211, 211, 0.8)",
                        "&:hover": {
                          backgroundColor: "rgba(176, 176, 176, 0.9)",
                        },
                      }}
                    >
                      <DownloadIcon />
                    </Fab>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
    </Card>
  );
};

export default SendOutDetails;
