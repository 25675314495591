import React, { useState } from "react";
import { Box, Typography, Card, CardContent, TextField, Button } from "@mui/material";
import axiosInstance from "../../utils/AxiosInstance";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { useAppDispatch } from "../../hooks";
import { useTranslation } from "react-i18next";
import { formatDateToString } from "../../utils/DateUtils";

interface LastDayEditProps {
  applicantId: string;
  initialLastDay: string | null;
  onSuccess: () => void;
}

const LastDayEdit: React.FC<LastDayEditProps> = ({ applicantId, initialLastDay, onSuccess }) => {
  const { t } = useTranslation();
  const [lastDay, setLastDay] = useState<string | null>(initialLastDay);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleLastDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastDay(event.target.value);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axiosInstance.put(`/applicant_support_info/${applicantId}/last_day`, { lastDay });
      onSuccess();
      dispatch(showSnackbar({ message: t("applicantDetails.lastDaySuccess"), severity: "success" }));
    } catch (error) {
      console.error("Error updating last day:", error);
      dispatch(showSnackbar({ message: t("applicantDetails.lastDayFailure"), severity: "error" }));
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  return (
    <Card variant="outlined" sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="subtitle1">{t("applicantDetails.lastDayLabel")}</Typography>
        {!isEditing ? (
          <Box>
            <Typography variant="body2">
              {lastDay ? formatDateToString(lastDay) : t("applicantDetails.lastDayNA")}
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => setIsEditing(true)}>
              {t("generic.edit")}
            </Button>
          </Box>
        ) : (
          <Box>
            <TextField variant="outlined" fullWidth type="date" value={lastDay || ""} onChange={handleLastDayChange} />
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSave} disabled={loading}>
              {t("generic.save")}
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default LastDayEdit;
