// components/ReportListComponent.tsx
import React from "react";
import { Box, Typography, Button, Card, CardContent } from "@mui/material";
import { BaseReportListItem } from "../types/Report";
import { formatDateToString, formatDateTimeToString } from "../utils/DateUtils";
import { useTranslation } from "react-i18next";

import { t } from "i18next";
import MaijaEmptyState from "./MaijaEmptyState";

interface ReportListComponentProps<T extends BaseReportListItem> {
  reports: T[];
  onShowReport: (reportId: string) => void;
  reportName: string;
}

const emptyStateComponent = <MaijaEmptyState message={t(`reportPreview.noReports`)} />;

export const ReportListComponent = <T extends BaseReportListItem>({
  reports,
  onShowReport,
}: ReportListComponentProps<T>) => {
  const { t } = useTranslation();

  console.log("BARF: Render ReportListComponent", reports.length);

  return (
    <Box sx={{ py: 2 }}>
      {reports.length === 0 ? (
        emptyStateComponent
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            py: 2,
            "& > *": { my: 1 },
          }}
        >
          {reports.map((report) => (
            <Card key={report.id} sx={{ minWidth: 300, position: "relative" }}>
              <CardContent>
                <Box display="flex" flexDirection="row" justifyContent="space-between">
                  <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2}>
                    <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                      {t(`reportPreview.listTitle`)} {formatDateToString(report.createdAt)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatDateTimeToString(report.createdAt)}
                    </Typography>
                  </Box>

                  <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 2 }}>
                    <Button variant="contained" color="primary" onClick={() => onShowReport(report.id)}>
                      {t(`reportPreview.showReport`)}
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};
