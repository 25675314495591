import React, { useState, useEffect } from "react";
import { alpha, Box, CircularProgress, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartData,
} from "chart.js";
import axiosInstance from "../../utils/AxiosInstance";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, ArcElement);

interface GraphVisualizerProps {
  applicantId: string;
}

interface ApplicationData {
  date: string;
  count: number;
}

const timeSpans = ["Last Week", "Last Month", "Last Year"];
const chartTypes = ["Line", "Bar"];

const GraphVisualizer: React.FC<GraphVisualizerProps> = ({ applicantId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const transparentPrimary = alpha(theme.palette.primary.main, 0.8);

  // Store the raw values in state
  const [timeSpan, setTimeSpan] = useState("Last Month");
  const [chartType, setChartType] = useState("Bar");
  const [chartData, setChartData] = useState<ChartData<"line" | "bar" | "pie"> | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/applications_over_time/${applicantId}`, {
          params: { timeSpan },
        });
        const data: ApplicationData[] = response.data;

        const endDate = dayjs();
        let startDate;
        switch (timeSpan) {
          case "Last Week":
            startDate = endDate.subtract(1, "week");
            break;
          case "Last Month":
            startDate = endDate.subtract(1, "month");
            break;
          case "Last Year":
            startDate = endDate.subtract(1, "year");
            break;
          default:
            startDate = endDate.subtract(1, "month");
        }

        const completeDates: string[] = [];
        let currentDate = startDate;
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, "day")) {
          completeDates.push(currentDate.format("YYYY-MM-DD"));
          currentDate = currentDate.add(1, "day");
        }

        const dateToCountMap: { [key: string]: number } = data.reduce<{ [key: string]: number }>((acc, entry) => {
          acc[entry.date] = entry.count;
          return acc;
        }, {});

        const formattedData: ChartData<"line" | "bar" | "pie"> = {
          labels: completeDates,
          datasets: [
            {
              label: t("applicantDetails.numberOfApplications"),
              data: completeDates.map((date) => dateToCountMap[date] || 0),
              borderColor: theme.palette.primary.main,
              backgroundColor: transparentPrimary,
            },
          ],
        };

        setChartData(formattedData);
      } catch (error) {
        console.error(t("applicantDetails.errorFetchingData"), error);
      }
    };

    fetchData();
  }, [timeSpan, applicantId, theme.palette.primary.main, transparentPrimary, t]);

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          callback: function (value: string | number) {
            if (typeof value === "number" && Number.isInteger(value)) {
              return value;
            }
            return null;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
  };
  const renderChart = () => {
    if (!chartData) {
      return <CircularProgress size={50} />;
    }

    switch (chartType) {
      case "Bar":
        return <Bar data={chartData as ChartData<"bar">} options={chartOptions} />;
      case "Pie":
        return <Pie data={chartData as ChartData<"pie">} options={chartOptions} />;
      case "Line":
      default:
        return <Line data={chartData as ChartData<"line">} options={chartOptions} />;
    }
  };

  return (
    <Box sx={{ mt: 4, p: 4, boxShadow: 0, height: 300 }}>
      <Typography variant="h6" gutterBottom>
        {t("applicantDetails.applicationsOverTime")}
      </Typography>
      <Select value={timeSpan} onChange={(e) => setTimeSpan(e.target.value)} variant="outlined" sx={{ mb: 2 }}>
        {timeSpans.map((span) => (
          <MenuItem key={span} value={span}>
            {t(`applicantDetails.${span.replace(" ", "").toLowerCase()}`)}
          </MenuItem>
        ))}
      </Select>
      <Select value={chartType} onChange={(e) => setChartType(e.target.value)} variant="outlined" sx={{ mb: 2, ml: 2 }}>
        {chartTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {t(`applicantDetails.${type.toLowerCase()}`)}
          </MenuItem>
        ))}
      </Select>
      <Box sx={{ height: "calc(100% - 64px)", position: "relative" }}>
        {chartData ? renderChart() : <Typography>{t("applicantDetails.loading")}</Typography>}
      </Box>
    </Box>
  );
};

export default GraphVisualizer;
