import { ApplicantAnalyticsItem } from "../../types/ApplicantAnalyticsItem";
import { CanTakeWorkImmediately, EducationLevels, EvaluationCriteria, Gender, SfiLevels } from "./AnalyticsCategories";

const ageGroups = ["16-24", "25-34", "35-44", "45-54", "55-64", "65-100+", "-"];
const loginOptions = ["today", "last_7_days", "last_30_days", "last_90_days", "last_365_days", "before", "never"];

const getAgeGroup = (age: number | null): string => {
  if (age !== null) {
    if (age >= 16 && age <= 24) return "16-24";
    else if (age >= 25 && age <= 34) return "25-34";
    else if (age >= 35 && age <= 44) return "35-44";
    else if (age >= 45 && age <= 54) return "45-54";
    else if (age >= 55 && age <= 64) return "55-64";
    else if (age >= 65) return "65-100+";
  }
  return "-";
};

export function calculateDaysAgo(date: string): number {
  const today = new Date().setHours(0, 0, 0, 0);
  const inputDate = new Date(date).setHours(0, 0, 0, 0);
  const diffTime = today - inputDate;
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

const getProfileCompletionRange = (profileCompletion: string | undefined): string => {
  const pc = Math.min(parseFloat(profileCompletion || "0") * 100, 99.99);
  return `${Math.floor(pc / 10) * 10}% - ${Math.floor(pc / 10) * 10 + 10}%`;
};

const getJobsAppliedRange = (jobsApplied: number | undefined): string => {
  const count = jobsApplied || 0;
  return count >= 100 ? "100+" : `${Math.floor(count / 10) * 10} - ${Math.floor(count / 10) * 10 + 9}`;
};

const getNonAppliedJobsRange = getJobsAppliedRange;

const getLatestLoginCategory = (latestLogin: string): string => {
  const daysAgo = calculateDaysAgo(latestLogin);
  if (daysAgo === 0) return "today";
  if (daysAgo <= 7) return "last_7_days";
  if (daysAgo <= 30) return "last_30_days";
  if (daysAgo <= 90) return "last_90_days";
  if (daysAgo <= 365) return "last_365_days";
  return new Date(latestLogin).getFullYear() === 1970 ? "never" : "before";
};

export const analyticsDataFields = [
  {
    name: "allApplicants",
    labelKey: "analyticsPage.fields.allApplicants",
    options: ["applicants"],
    filter: null,
    groupBy: () => "applicants",
  },
  {
    name: "gender",
    labelKey: "analyticsPage.fields.gender",
    options: Object.values(Gender),
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) =>
      !selectedValue || applicant.gender === selectedValue,
    groupBy: (applicant: ApplicantAnalyticsItem) => applicant.gender || "-",
  },
  {
    name: "age",
    labelKey: "analyticsPage.fields.age",
    options: ageGroups,
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) => {
      const ageGroup = getAgeGroup(applicant.age);
      return !selectedValue || ageGroup === selectedValue;
    },
    groupBy: (applicant: ApplicantAnalyticsItem) => getAgeGroup(applicant.age),
  },
  {
    name: "sfiLevel",
    labelKey: "analyticsPage.fields.sfiLevel",
    options: Object.values(SfiLevels),
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) =>
      !selectedValue || applicant.sfiLevel === selectedValue,
    groupBy: (applicant: ApplicantAnalyticsItem) => applicant.sfiLevel || "-",
  },
  {
    name: "evaluationCriteria",
    labelKey: "analyticsPage.fields.evaluationCriteria",
    options: Object.values(EvaluationCriteria),
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) =>
      !selectedValue || applicant.evaluationCriteria === selectedValue,
    groupBy: (applicant: ApplicantAnalyticsItem) => applicant.evaluationCriteria || "-",
  },
  {
    name: "canTakeWorkImmediately",
    labelKey: "analyticsPage.fields.canTakeWorkImmediately",
    options: Object.values(CanTakeWorkImmediately),
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) => {
      const canTakeWork =
        applicant.canTakeWorkImmediately !== undefined ? String(applicant.canTakeWorkImmediately) : "-";
      return !selectedValue || canTakeWork === selectedValue;
    },
    groupBy: (applicant: ApplicantAnalyticsItem) => {
      return applicant.canTakeWorkImmediately !== undefined ? String(applicant.canTakeWorkImmediately) : "-";
    },
  },
  {
    name: "educationLevel",
    labelKey: "analyticsPage.fields.educationLevel",
    options: Object.values(EducationLevels),
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) =>
      !selectedValue || applicant.educationLevel === selectedValue,
    groupBy: (applicant: ApplicantAnalyticsItem) => applicant.educationLevel || "-",
  },
  {
    name: "profileCompletion",
    labelKey: "analyticsPage.fields.profileCompletion",
    options: Array.from({ length: 10 }, (_, i) => `${i * 10}% - ${(i + 1) * 10}%`),
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) => {
      const pcRange = getProfileCompletionRange(applicant.profileCompletion);
      return !selectedValue || pcRange === selectedValue;
    },
    groupBy: (applicant: ApplicantAnalyticsItem) => getProfileCompletionRange(applicant.profileCompletion),
  },
  {
    name: "appliedJobsLast30Days",
    labelKey: "analyticsPage.fields.appliedJobsLast30Days",
    options: [...Array.from({ length: 10 }, (_, i) => `${i * 10} - ${(i + 1) * 10 - 1}`), "100+"],
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) => {
      const jobsRange = getJobsAppliedRange(applicant.appliedJobsLast30Days);
      return !selectedValue || jobsRange === selectedValue;
    },
    groupBy: (applicant: ApplicantAnalyticsItem) => getJobsAppliedRange(applicant.appliedJobsLast30Days),
  },
  {
    name: "nonAppliedJobsCount",
    labelKey: "analyticsPage.fields.nonAppliedJobsCount",
    options: [...Array.from({ length: 10 }, (_, i) => `${i * 10} - ${(i + 1) * 10 - 1}`), "100+"],
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) => {
      const jobsRange = getNonAppliedJobsRange(applicant.nonAppliedJobsCount);
      return !selectedValue || jobsRange === selectedValue;
    },
    groupBy: (applicant: ApplicantAnalyticsItem) => getNonAppliedJobsRange(applicant.nonAppliedJobsCount),
  },
  {
    name: "latestLogin",
    labelKey: "analyticsPage.fields.latestLogin",
    options: loginOptions,
    filter: (applicant: ApplicantAnalyticsItem, selectedValue: string) => {
      const loginCategory = getLatestLoginCategory(applicant.latestLogin);
      return !selectedValue || loginCategory === selectedValue;
    },
    groupBy: (applicant: ApplicantAnalyticsItem) => getLatestLoginCategory(applicant.latestLogin),
  },
];
