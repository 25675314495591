import { CompanySettings } from "../../types/CompanySettings";
import axiosInstance from "../../utils/AxiosInstance";

export async function updateSettings(data: CompanySettings): Promise<void> {
  try {
    await axiosInstance.post("/company/settings", data);
  } catch (error) {
    console.error("Failed to update settings:", error);
    throw error;
  }
}

export async function getSettings(companyId: string): Promise<CompanySettings | null> {
  try {
    const response = await axiosInstance.get<CompanySettings>("/company_settings_login", {
      params: { company_id: companyId },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to retrieve settings:", error);
    return null;
  }
}
