import { OfficeReport } from "../../types/OfficeReport";
import axiosInstance from "../../utils/AxiosInstance";
import { AxiosResponse } from "axios";

export const getOfficeReport = async (officeId: string, reportId: string): Promise<OfficeReport> => {
  const response: AxiosResponse<OfficeReport> = await axiosInstance.get("/office/report", {
    params: {
      office_id: officeId,
      report_id: reportId,
    },
  });
  return response.data;
};

export async function getOfficeReports(officeId: string): Promise<OfficeReport[]> {
  try {
    const response = await axiosInstance.get("/office/reports", {
      params: {
        office_id: officeId,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const getNewOfficeReport = async (officeId: string): Promise<OfficeReport> => {
  const response: AxiosResponse<OfficeReport> = await axiosInstance.get("/office/report_create", {
    params: {
      office_id: officeId,
    },
  });
  return response.data;
};

export const sendOfficeReportEmail = async (officeId: string, reportId: string): Promise<string> => {
  try {
    const response: AxiosResponse<string> = await axiosInstance.get("/office/report/email", {
      params: {
        office_id: officeId,
        report_id: reportId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending office report email:", error);
    throw error;
  }
};
