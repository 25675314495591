import React from "react";
import { Box, Paper, useTheme, alpha } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, LabelList, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";

interface AnalyticsBarChartProps {
  data: { type: string; count: number }[];
}

const AnalyticsBarChartComponent: React.FC<AnalyticsBarChartProps> = ({ data }) => {
  const theme = useTheme();
  const transparentPrimary = alpha(theme.palette.primary.main, 0.8);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        margin: 4,
        width: "95%",
      }}
    >
      <Paper elevation={3}>
        <ResponsiveContainer height={600}>
          <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="type" />
            <YAxis dataKey="count" />
            <Tooltip formatter={(value) => [`${value}`, t("analyticsPage.hoverCount")]} />
            <Bar dataKey="count" fill={transparentPrimary}>
              <LabelList dataKey="count" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  );
};

export default React.memo(AnalyticsBarChartComponent);
