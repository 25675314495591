import userSlice from "./reducers/userSlice";
import snackbarSlice from "./reducers/snackbarSlice";
import planningPageSlice from "./reducers/planningPageSlice";
import applicantDetailsPageSlice from "./reducers/applicantDetailsPageSlice";
import applicantListPageSlice from "./reducers/applicantListPageSlice";
import jobCoachListPageSlice from "./reducers/jobCoachListPageSlice";
import jobCoachDetailsPageSlice from "./reducers/jobCoachDetailsPageSlice";
import officeListPageSlice from "./reducers/officeListPageSlice";
import officeDetailsPageSlice from "./reducers/officeDetailsPageSlice";
import companyCreatePageSlice from "./reducers/companyCreatePageSlice";
import themeReducer from "./reducers/themeSlice";
import analyticsPageSlice from "./reducers/analyticsPageSlice";

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

export const store = configureStore({
  reducer: {
    user: userSlice,
    planningPageSlice: planningPageSlice,
    applicantListPageSlice: applicantListPageSlice,
    applicantDetailsPageSlice: applicantDetailsPageSlice,
    jobCoachListPageSlice: jobCoachListPageSlice,
    jobCoachDetailsPageSlice: jobCoachDetailsPageSlice,
    officeListPageSlice: officeListPageSlice,
    officeDetailsPageSlice: officeDetailsPageSlice,
    companyCreatePageSlice: companyCreatePageSlice,
    snackbar: snackbarSlice,
    theme: themeReducer,
    analyticsPageSlice: analyticsPageSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export default store;
