import { useNavigate, useLocation, Navigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { useAppDispatch } from "../hooks";
import { showSnackbar } from "./../reducers/snackbarSlice";
import {
  setPrimaryColor as setReduxPrimaryColor,
  setSecondaryColor as setReduxSecondaryColor,
  setTertiaryColor as setReduxTertiaryColor,
  setLogo as setReduxLogo,
  setLoginImage as setReduxLoginImage,
  setSettingsLoaded as setReduxSettingsLoaded,
} from "../reducers/themeSlice";
import MaijaLoadingPage from "./MaijaLoadingPage";
import { getSettings } from "../pages/settings/SettingsRepository";
import MaijaAppBar from "./MaijaAppBar";
import MaijaRoute, {
  APPLICANT_LIST_ROUTE,
  CREATE_APPLICANT_ROUTE,
  PLANNING_ROUTE,
  OFFICE_LIST_ROUTE,
  JOB_COACH_LIST_ROUTE,
  COMPANY_CREATE_ROUTE,
  JOB_COACH_DETAILS_ROUTE,
  ANALYTICS_ROUTE,
  ViewMode,
  LOGIN_ROUTE,
} from "../Routes";
import { SecurityLevel } from "../types/User";
import { useTranslation } from "react-i18next";

type ProtectedRouteProps = {
  route: MaijaRoute;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route, children }) => {
  const { user, loading: authLoading } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [settingsLoading, setSettingsLoading] = useState(true);

  useEffect(() => {
    if (!authLoading && !user) {
      navigate(LOGIN_ROUTE.path, { replace: true, state: { from: location } });
    }
  }, [authLoading, user, navigate, location]);

  useEffect(() => {
    const fetchAndSetCompanySettings = async (companyId: string | undefined) => {
      if (!companyId) {
        dispatch(setReduxSettingsLoaded(true));
        setSettingsLoading(false);
        return;
      }
      try {
        const settings = await getSettings(companyId);
        if (settings) {
          dispatch(setReduxPrimaryColor(settings.primary_color || "#FFFFFF"));
          dispatch(setReduxSecondaryColor(settings.secondary_color || "#FFFFFF"));
          dispatch(setReduxTertiaryColor(settings.tertiary_color || "#FFFFFF"));
          dispatch(setReduxLogo(settings.logo ?? null));
          dispatch(setReduxLoginImage(settings.login_image ?? null));
        }
      } catch (error) {
        console.error("Failed to load company settings:", error);
      } finally {
        dispatch(setReduxSettingsLoaded(true));
        setSettingsLoading(false);
      }
    };

    if (user && settingsLoading) {
      fetchAndSetCompanySettings(user.companyId);
    } else {
      setSettingsLoading(false);
    }
  }, [user, settingsLoading, dispatch]);

  if (authLoading || settingsLoading) {
    return <MaijaLoadingPage isFullscreen />;
  }

  if (!user) {
    return null;
  }

  if (route.securityLevel && user.securityLevel < route.securityLevel) {
    dispatch(
      showSnackbar({
        message: t("generic.error"),
        severity: "error",
      }),
    );
    return <Navigate to={APPLICANT_LIST_ROUTE.path} replace />;
  }

  let toolbarRoutes = [];

  if (user.securityLevel >= SecurityLevel.CompanyAdmin) {
    toolbarRoutes = [
      OFFICE_LIST_ROUTE,
      JOB_COACH_LIST_ROUTE,
      APPLICANT_LIST_ROUTE,
      PLANNING_ROUTE,
      COMPANY_CREATE_ROUTE,
      ANALYTICS_ROUTE,
    ];
  } else {
    toolbarRoutes = [JOB_COACH_DETAILS_ROUTE, APPLICANT_LIST_ROUTE, PLANNING_ROUTE, CREATE_APPLICANT_ROUTE];
  }

  const containerStyle = {
    height: "100vh",
    minWidth: route.viewMode === ViewMode.Wide ? "1000px" : "100%",
    margin: "0 auto",
  };

  return (
    <div style={containerStyle}>
      <MaijaAppBar routes={toolbarRoutes} />
      <div style={{ height: "100vh" }}>{children}</div>
    </div>
  );
};

export default ProtectedRoute;
