import React, { ReactElement, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { APPLICANT_LIST_ROUTE, OFFICE_LIST_ROUTE } from "../Routes";
import { SecurityLevel } from "../types/User";

interface PublicRouteProps {
  children: ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isAuthenticated, user } = useContext(AuthContext);

  if (isAuthenticated && user) {
    const targetRoute =
      user.securityLevel >= SecurityLevel.CompanyAdmin ? OFFICE_LIST_ROUTE.path : APPLICANT_LIST_ROUTE.path;
    return <Navigate to={targetRoute} replace />;
  }

  return children;
};

export default PublicRoute;
