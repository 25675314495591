import { useTranslation } from "react-i18next";
import { ApplicantDetails } from "../../types/ApplicantDetails";
import { formatDateToString } from "../../utils/DateUtils";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import DisplayedValue from "../../components/DisplayedValue";
import { TFunction } from "i18next";

export interface SuccessParamtersProps {
  applicantDetails: ApplicantDetails;
}

export const SuccessParamters: React.FC<SuccessParamtersProps> = ({ applicantDetails }) => {
  const { t } = useTranslation();

  const parameters = [
    {
      name: t("applicantDetails.evaluationCriteria"),
      parameter: applicantDetails.successParameters.evaluationCriteria.parameter,
      value: applicantDetails.successParameters.evaluationCriteria.value,
    },
    {
      name: t("applicantDetails.latestLogin"),
      parameter: formatDateToString(applicantDetails.successParameters.latestLogin.parameter),
      value: applicantDetails.successParameters.latestLogin.value,
    },
    {
      name: t("applicantDetails.appliedJobsLast30Days"),
      parameter: applicantDetails.successParameters.appliedJobsLast7Days.parameter.toString(),
      value: applicantDetails.successParameters.appliedJobsLast7Days.value,
    },
    {
      name: t("applicantDetails.lastPhysicalMeeting"),
      parameter: formatDateToString(applicantDetails.successParameters.lastestPhysicalMeeting.parameter),
      value: applicantDetails.successParameters.lastestPhysicalMeeting.value,
    },
    {
      name: t("applicantDetails.completedProfile"),
      parameter: applicantDetails.successParameters.completedProfile.parameter,
      value: applicantDetails.successParameters.completedProfile.value,
    },
    {
      name: t("applicantDetails.lastVirtualMeeting"),
      parameter: formatDateToString(applicantDetails.successParameters.lastestVirtualMeeting.parameter),
      value: applicantDetails.successParameters.lastestVirtualMeeting.value,
    },
  ];

  return (
    <Box sx={{ pt: 3 }}>
      <Card elevation={0} sx={{ backgroundColor: "#F9F9F9", px: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mb: 5, mt: 1, ml: -2 }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box
                  width={31}
                  height={31}
                  borderRadius="50%"
                  bgcolor={getSuccessParameterColor(applicantDetails.successParameters.overallScore.value)}
                  mr={2}
                ></Box>
                <Typography variant="h5" align="center">
                  {getOverallMessage(t, applicantDetails.successParameters.overallScore.value)}
                </Typography>
              </Box>
            </Grid>

            {parameters.map((param, index) => (
              <Grid item xs={6} key={index}>
                <Box display="flex" alignItems="center">
                  <Box
                    width={20}
                    height={20}
                    borderRadius="50%"
                    bgcolor={getSuccessParameterColor(param.value)}
                    mr={2}
                  ></Box>
                  <Typography variant="body1">
                    <DisplayedValue name={param.name} value={param.parameter} />
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

function getOverallMessage(t: TFunction, value: number): string {
  if (value >= 0 && value < 0.25) {
    return t("applicantDetails.effortNotEnough");
  } else if (value >= 0.25 && value < 0.5) {
    return t("applicantDetails.effortSome");
  } else if (value >= 0.5 && value < 0.75) {
    return t("applicantDetails.likelyEmployment");
  } else if (value >= 0.75 && value <= 1) {
    return t("applicantDetails.veryLikelyEmployment");
  } else {
    return "";
  }
}

const getSuccessParameterColor = (value: number) => {
  const red = value > 0.5 ? Math.floor(255 * (1 - value) * 2) : 255;
  const green = value < 0.5 ? Math.floor(255 * value * 2) : 255;
  return `rgb(${red}, ${green}, 0)`;
};
