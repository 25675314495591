import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { OfficeReportListItem } from "../../types/OfficeReport";
import { ReportListComponent } from "../../components/ReportsListComponent";
import { getOfficeReports } from "./OfficeReportsRepository";
import { officeDetailsPageSlice } from "../../reducers/officeDetailsPageSlice";

interface OfficeReportsComponentProps {
  officeId: string;
  onShowReport: (reportId: string) => void;
}

export const OfficeReportsComponent: React.FC<OfficeReportsComponentProps> = ({ officeId, onShowReport }) => {
  const dispatch = useAppDispatch();
  const reports = useAppSelector((state) => state.officeDetailsPageSlice.reportListItemsMap[officeId] || []);
  useEffect(() => {
    getOfficeReports(officeId).then((data) => {
      const sortedData = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      dispatch(
        officeDetailsPageSlice.actions.setOfficeDetailsReportsList({
          officeId,
          reports: sortedData,
        }),
      );
    });
  }, [officeId, dispatch]);

  return (
    <ReportListComponent<OfficeReportListItem>
      reports={reports}
      onShowReport={onShowReport}
      reportName="officeDetails"
    />
  );
};
