import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useAppSelector, useAppDispatch } from "../hooks";
import { hideSnackbar } from "../reducers/snackbarSlice";

export interface MaijaSnackbarProps {
  message: string;
  autoHideDuration?: number;
  severity?: "success" | "info" | "warning" | "error";
  onClose?: () => void;
}

const MaijaSnackbar: React.FC = () => {
  const snackbar = useAppSelector((state) => state.snackbar.snackbar);
  const dispatch = useAppDispatch();
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackbar !== undefined}
      autoHideDuration={snackbar?.autoHideDuration ?? 3000}
      onClose={() => {
        dispatch(hideSnackbar());
      }}
    >
      <Alert
        elevation={1}
        onClose={() => {
          dispatch(hideSnackbar());
        }}
        severity={snackbar?.severity}
        sx={{ width: "100%" }}
        data-cy="alert"
      >
        {snackbar?.message}
      </Alert>
    </Snackbar>
  );
};

export default MaijaSnackbar;
