import styled from "@emotion/styled";
import { Paper, Skeleton } from "@mui/material";
import { ReactNode, useLayoutEffect, useRef, useState } from "react";

const calculateHeightFromWidth = (width: number) => {
  return width * 1.414; // A4 aspect ratio (21cm/29.7cm)
};

const StyledA4Paper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  width: "100%",
});

export interface MaijaA4PaperProps {
  children?: ReactNode;
  loading?: boolean; // Prop to determine if a skeleton should be displayed
}

export const MaijaA4Paper: React.FC<MaijaA4PaperProps> = ({ children, loading = false }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<string>("auto");

  const updateHeight = () => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      setHeight(`${calculateHeightFromWidth(width)}px`);
    }
  };

  useLayoutEffect(() => {
    updateHeight(); // Initial update
    window.addEventListener("resize", updateHeight); // Add resize listener

    return () => {
      window.removeEventListener("resize", updateHeight); // Clean up listener on unmount
    };
  }, []);

  // Choose to render either the skeleton or the provided children
  const content = loading ? <Skeleton variant="rectangular" width="100%" height={height} animation="wave" /> : children;

  return (
    <StyledA4Paper ref={ref} style={{ height }}>
      {content}
    </StyledA4Paper>
  );
};
