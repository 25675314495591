export enum SfiLevels {
  MOTHER_TONGUE = "MOTHER_TONGUE",
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  SAS_G = "SAS_G",
}

export enum EvaluationCriteria {
  A = "A",
  B = "B",
  C = "C",
}

export enum CanTakeWorkImmediately {
  YES = "true",
  NO = "false",
}

export enum Gender {
  MAN = "MAN",
  Woman = "WOMAN",
  Other = "OTHER",
}

export enum EducationLevels {
  NO_EDUCATION = "NO_EDUCATION",
  LESS_THAN_SEVEN = "LESS_THAN_SEVEN",
  SEVEN_TO_NINE = "SEVEN_TO_NINE",
  HIGH_SCHOOL = "HIGH_SCHOOL",
  HIGHER_EDU = "HIGHER_EDU",
}
