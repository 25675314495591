import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Alert,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import EmailIcon from "@mui/icons-material/Email";
import NoteIcon from "@mui/icons-material/Note";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import axiosInstance from "../../utils/AxiosInstance";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { formatDateTimeToString } from "../../utils/DateUtils";

interface Note {
  id: string;
  title: string;
  main_text: string;
  created_date: string;
  job_coach_id: string;
  job_coach_name: string;
  type: string;
}

interface NotesComponentProps {
  applicantId: string;
}

const noteIcons: { [key: string]: React.ReactNode } = {
  "virtual meeting": <EventIcon />,
  "physical meeting": <PeopleIcon />,
  "e-mail": <EmailIcon />,
  general: <NoteIcon />,
};

const NotesComponent: React.FC<NotesComponentProps> = ({ applicantId }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [title, setTitle] = useState("");
  const [mainText, setMainText] = useState("");
  const [noteType, setNoteType] = useState("general"); // New state for note type
  const [error, setError] = useState("");
  const [showAddNote, setShowAddNote] = useState(false);
  const { t } = useTranslation();

  const jobCoachId = useAppSelector((state: RootState) => state.user.user?.jobCoachId);

  useEffect(() => {
    axiosInstance
      .get(`/notes/${applicantId}`)
      .then((response) => setNotes(response.data))
      .catch((error) => console.error("Error fetching notes:", error));
  }, [applicantId]);

  const handleAddNote = () => {
    if (!title || !mainText) {
      setError(t("applicantDetails.note.titleAndMainTextRequired"));
      return;
    }

    axiosInstance
      .post("/note/create", {
        applicant_id: applicantId,
        job_coach_id: jobCoachId,
        title: title,
        main_text: mainText,
        type: noteType, // Pass the note type
      })
      .then((response) => {
        setNotes([...notes, response.data]);
        setTitle("");
        setMainText("");
        setNoteType("general"); // Reset to default type
        setShowAddNote(false);
        setError("");
      })
      .catch((error) => {
        console.error("Error creating note:", error);
        setError(t("applicantDetails.note.failedToCreateNote"));
      });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Button variant="contained" color="primary" sx={{ mb: 2 }} onClick={() => setShowAddNote(true)}>
        {t("applicantDetails.note.addNote")}
      </Button>
      {showAddNote && (
        <Paper elevation={3} sx={{ p: 2, mb: 4, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("applicantDetails.note.title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("applicantDetails.note.mainText")}
                value={mainText}
                onChange={(e) => setMainText(e.target.value)}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Note Type Selector */}
              <FormControl fullWidth variant="outlined">
                <InputLabel id="note-type-label">{t("applicantDetails.note.noteType")}</InputLabel>
                <Select
                  labelId="note-type-label"
                  id="note-type-select"
                  value={noteType}
                  onChange={(e) => setNoteType(e.target.value)}
                  label={t("applicantDetails.note.noteType")}
                >
                  <MenuItem value="virtual meeting">{t("applicantDetails.note.virtualMeeting")}</MenuItem>
                  <MenuItem value="physical meeting">{t("applicantDetails.note.physicalMeeting")}</MenuItem>
                  <MenuItem value="e-mail">{t("applicantDetails.note.email")}</MenuItem>
                  <MenuItem value="general">{t("applicantDetails.note.general")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary" onClick={handleAddNote}>
                {t("applicantDetails.note.submit")}
              </Button>
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "auto",
          p: 2,
          "& > *": { my: 1 },
        }}
      >
        {notes.map((note) => (
          <motion.div
            key={note.id}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Card sx={{ minWidth: 300, position: "relative" }}>
              <CardContent>
                {/* Icon for note type */}
                <Box sx={{ position: "absolute", top: 10, right: 10 }}>
                  {noteIcons[note.type] || <NoteIcon />} {/* Fallback to general icon */}
                </Box>
                <Typography variant="h6">{note.title}</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {note.main_text}
                </Typography>
                <Box display="block">
                  {note.job_coach_name && (
                    <Typography variant="caption" color="textSecondary">
                      {t("applicantDetails.note.by")} {note.job_coach_name}
                    </Typography>
                  )}
                </Box>
                <Box display="block">
                  <Typography variant="caption" color="textSecondary">
                    {formatDateTimeToString(note.created_date)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </Box>
    </Box>
  );
};

export default NotesComponent;
