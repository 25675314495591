import React, { useState } from "react";

import { OfficeReport } from "../../types/OfficeReport";
import { useAppDispatch } from "../../hooks";
import ReportPreviewModal, { PreviewModalMode } from "../../components/ReportPreviewModal";
import { officeDetailsPageSlice } from "../../reducers/officeDetailsPageSlice";
import { getNewOfficeReport, getOfficeReport, sendOfficeReportEmail } from "./OfficeReportsRepository";

interface OfficeReportPreviewModalProps {
  isOpen: boolean;
  officeId: string;
  officeName: string;
  reportMode: PreviewModalMode;
  handleClose: () => void;
}

const OfficeReportPreviewModal: React.FC<OfficeReportPreviewModalProps> = ({
  isOpen,
  officeId,
  officeName,
  reportMode,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const [officeReport, setOfficeReport] = useState<OfficeReport | undefined>(undefined);

  const fetchReport = async (reportId?: string): Promise<OfficeReport> => {
    if (reportId) {
      const newReport = await getOfficeReport(officeId, reportId);
      return newReport;
    } else {
      const newReport = await getNewOfficeReport(officeId);
      dispatch(
        officeDetailsPageSlice.actions.addOfficeDetailsReportsList({ officeId: officeId, report: { ...newReport } }),
      );
      return newReport;
    }
  };

  const sendReportEmail = async (reportId: string): Promise<void> => {
    await sendOfficeReportEmail(officeId, reportId);
  };

  return (
    <ReportPreviewModal<OfficeReport>
      isOpen={isOpen}
      handleClose={handleClose}
      reportMode={reportMode}
      fetchReport={fetchReport}
      sendReportEmail={sendReportEmail}
      reportName={officeName}
      report={officeReport}
      setReport={setOfficeReport}
      additionalActionContent={null}
    />
  );
};

export default OfficeReportPreviewModal;
