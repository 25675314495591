import axiosInstance from "../../../utils/AxiosInstance";
import { SendOut } from "../../../types/SendOut";
import { PdfWithThumbnails } from "../../../types/PdfWithThumbnails";

export const fetchSendOuts = async (): Promise<SendOut[]> => {
  const response = await axiosInstance.get("/sendouts");
  return response.data;
};

export const fetchSendOutFile = async (sendout_id: string): Promise<PdfWithThumbnails> => {
  const response = await axiosInstance.get(`/sendout/file`, {
    params: { sendout_id },
  });
  return response.data;
};

export const createSendOut = async (sendOut: SendOut): Promise<SendOut> => {
  const response = await axiosInstance.post("/sendout/create", sendOut);
  return response.data;
};

export const editSendOut = async (sendOut: SendOut): Promise<SendOut> => {
  const response = await axiosInstance.post("/sendout/edit", sendOut);
  return response.data;
};

export const deleteSendOut = async (id: string): Promise<void> => {
  await axiosInstance.post("/sendout/delete", { id });
};
