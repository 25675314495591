import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { OfficeDetails } from "../types/OfficeDetails";
import { OfficeReportListItem } from "../types/OfficeReport";

interface OfficeDetailsPageState {
  officesMap: { [id: string]: OfficeDetails };
  reportListItemsMap: { [id: string]: OfficeReportListItem[] };
}

const initialState: OfficeDetailsPageState = {
  officesMap: {},
  reportListItemsMap: {},
};

export const officeDetailsPageSlice = createSlice({
  name: "officeDetails",
  initialState,
  reducers: {
    setOffice: (state, action: PayloadAction<OfficeDetails>) => {
      state.officesMap[action.payload.officeId] = action.payload;
    },
    setOfficeDetailsReportsList: (
      state,
      action: PayloadAction<{
        officeId: string;
        reports: OfficeReportListItem[];
      }>,
    ) => {
      state.reportListItemsMap[action.payload.officeId] = action.payload.reports;
    },
    addOfficeDetailsReportsList: (
      state,
      action: PayloadAction<{
        officeId: string;
        report: OfficeReportListItem;
      }>,
    ) => {
      const { officeId, report } = action.payload;

      state.reportListItemsMap[officeId] = [...(state.reportListItemsMap[officeId] || []), report].sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    },

    clearOfficeDetailsPageSlice: (state) => {
      state.officesMap = {};
      state.reportListItemsMap = {};
    },
  },
});

export const { setOffice, setOfficeDetailsReportsList, addOfficeDetailsReportsList, clearOfficeDetailsPageSlice } =
  officeDetailsPageSlice.actions;
export const officeDetailsPage = (state: RootState) => state.applicantDetailsPageSlice;
export default officeDetailsPageSlice.reducer;
