import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useAppDispatch } from "../../hooks";
import { showSnackbar } from "../../reducers/snackbarSlice";
import { UpdateJobCoachDetails } from "./JobCoachDetailsRepository";
import { updateJobCoachDetails as apiUpdateJobCoachDetails } from "./JobCoachDetailsRepository";
import { JobCoachDetailsFieldType, JobCoachDetailsFieldValueMap } from "./JobCoachDetailsField";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../utils/StringUtils";
import { pushJobCoach } from "../../reducers/jobCoachDetailsPageSlice";

interface JobCoachDetailsModalProps<T extends JobCoachDetailsFieldType> {
  type: JobCoachDetailsFieldType;
  open: boolean;
  onClose: () => void;
  getUpdatedJobCoachDetails: (value: JobCoachDetailsFieldValueMap[T]) => UpdateJobCoachDetails | undefined;
  initialValue?: string;
  jobCoachId: string;
  offices: Office[];
}

interface Office {
  id: string;
  name: string;
}

const JobCoachDetailsModal = <T extends JobCoachDetailsFieldType>({
  open,
  onClose,
  getUpdatedJobCoachDetails,
  type,
  initialValue = "",
  offices,
}: JobCoachDetailsModalProps<T>) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<JobCoachDetailsFieldValueMap[T]>(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSave = async () => {
    try {
      let finalValue = value;

      if (type === JobCoachDetailsFieldType.OfficeId) {
        const selectedOffice = offices.find((office) => office.name === value);
        if (selectedOffice) {
          finalValue = selectedOffice.id;
        }
      }

      const updatedJobCoachDetails = getUpdatedJobCoachDetails(finalValue);
      if (!updatedJobCoachDetails) return;

      const apiUpdatedJobCoachDetails = await apiUpdateJobCoachDetails(updatedJobCoachDetails);
      dispatch(pushJobCoach(apiUpdatedJobCoachDetails));
      dispatch(showSnackbar({ message: t("jobCoachDetails.saveSuccess", { typeLabel: type }), severity: "success" }));
    } catch (error) {
      dispatch(showSnackbar({ message: t("jobCoachDetails.saveError"), severity: "error" }));
    } finally {
      onClose();
    }
  };

  const typeLabel = () => {
    switch (type) {
      case JobCoachDetailsFieldType.OfficeId:
        return t("jobCoachDetails.office");
      case JobCoachDetailsFieldType.Email:
        return t("jobCoachDetails.emailLabel");
      case JobCoachDetailsFieldType.PhoneNumber:
        return t("jobCoachDetails.phoneNumberLabel");
      default:
        return "";
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && (typeof value === "string" ? value.trim() : value)) {
      handleSave();
      event.preventDefault();
    }
  };

  const renderField = () => {
    if (type === JobCoachDetailsFieldType.OfficeId) {
      return (
        <Box sx={{ p: 2 }}>
          <Paper elevation={3} sx={{ p: 2, mb: 4, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="office-select-label">{t("jobCoachDetails.office")}</InputLabel>
                  <Select
                    labelId="office-select-label"
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    label={t("jobCoachDetails.office")}
                  >
                    {offices.map((office) => (
                      <MenuItem key={office.id} value={office.name}>
                        {office.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      );
    }
    return (
      <TextField
        inputRef={inputRef}
        autoFocus
        margin="dense"
        label={capitalizeFirstLetter(typeLabel())}
        type="text"
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyPress}
      />
    );
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setTimeout(() => {
      if (open && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("jobCoachDetails.editLabel", { typeLabel: typeLabel() })}</DialogTitle>
      <DialogContent sx={{ width: "600px" }}>{renderField()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("generic.cancel")}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t("jobCoachDetails.saveButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobCoachDetailsModal;
